import React, { useState, useEffect } from 'react';
import { Tabs, Tab, Box, Typography, Accordion, AccordionSummary, AccordionDetails, useMediaQuery, CircularProgress } from '@mui/material';
import { FaAngleDown } from "react-icons/fa";
import axios from 'axios';
import './App.css'; // Import the CSS file

const FAQTabs = () => {
  const [categories, setCategories] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [faqs, setFaqs] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isFaqLoading, setIsFaqLoading] = useState(false);
  const isMobile = useMediaQuery('(max-width: 768px)');

  useEffect(() => {
    const fetchCategoriesAndFaqs = async () => {
      setIsLoading(true);
      try {
        // Fetch categories first
        const categoryResponse = await axios.get('https://backend.mysecretlit.com/api/getFaqCategories');
        const categoryData = categoryResponse.data.data;
        setCategories(categoryData);

        if (categoryData.length > 0) {
          // Set the first category as selected by default
          const firstCategoryId = categoryData[0]._id;
          setSelectedCategoryId(firstCategoryId);

          // Fetch FAQs for the first category
          await fetchFaqs(firstCategoryId);
        }
      } catch (error) {
        console.error('Error fetching categories and FAQs:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCategoriesAndFaqs();
  }, []);

  const fetchFaqs = async (categoryId) => {
    setIsFaqLoading(true);
    try {
      const faqResponse = await axios.get(`https://backend.mysecretlit.com/api/getFaqQuestions/${categoryId}`);
      setFaqs(faqResponse.data.data.faq || []);
    } catch (error) {
      console.error('Error fetching FAQs:', error);
    } finally {
      setIsFaqLoading(false);
    }
  };

  const handleTabChange = (event, newValue) => {
    const selectedCategory = categories[newValue];
    setSelectedCategoryId(selectedCategory._id);
    fetchFaqs(selectedCategory._id); // Fetch FAQs for the selected category
    setExpanded(false); // Close all accordions when switching tabs
  };

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  if (isLoading) {
    return (
      <div className="spinner-container">
        <CircularProgress color="error" />
      </div>
    );
  }

  return (
    <Box>
      <h2 className='h2-main-heading' style={{ paddingTop: 0, borderBottom: '1px solid white', width: '100%', height: '2.5em', display: 'flex', alignItems: 'center' }}>FAQ'S</h2>
      {isMobile ? (
        categories.map((category, tabIndex) => (
          <div key={tabIndex}>
            <h2 className='h2-main-heading' style={{ paddingTop: 0, fontSize: 14, width: '100%', marginTop: 10, fontWeight: 400 }}>{category.name}</h2>
            {isFaqLoading ? (
              <div className="spinner-container">
                <CircularProgress color="error" />
              </div>
            ) : faqs.length > 0 ? (
              faqs.map((item, idx) => (
                <Accordion
                  key={idx}
                  expanded={expanded === idx}
                  onChange={handleAccordionChange(idx)}
                >
                  <AccordionSummary
                    expandIcon={<FaAngleDown style={{ color: 'white' }} />}
                    aria-controls={`panel${idx}-content`}
                    id={`panel${idx}-header`}
                  >
                    <p style={{ marginBottom: 0, color: 'white', textAlign: 'left', fontSize: 12 }} className='td-list-styles'>{item.question}</p>
                  </AccordionSummary>
                  <AccordionDetails>
                    <p style={{ marginBottom: 0, textAlign: 'left', fontSize: 10 }} className='td-list-styles'>{item.answer}</p>
                  </AccordionDetails>
                </Accordion>
              ))
            ) : (
              <Typography>No content available.</Typography>
            )}
          </div>
        ))
      ) : (
        <>
          <Tabs value={categories.findIndex(cat => cat._id === selectedCategoryId)} onChange={handleTabChange} aria-label="FAQ Tabs">
            {categories.map((category, index) => (
              <Tab key={index} label={category.name} />
            ))}
          </Tabs>
          <Box>
            {isFaqLoading ? (
              <div className="spinner-container">
                <CircularProgress color="error" />
              </div>
            ) : faqs.length > 0 ? (
              faqs.map((item, idx) => (
                <Accordion
                  key={idx}
                  expanded={expanded === idx}
                  onChange={handleAccordionChange(idx)}
                >
                  <AccordionSummary
                    expandIcon={<FaAngleDown style={{ color: 'white' }} />}
                    aria-controls={`panel${idx}-content`}
                    id={`panel${idx}-header`}
                  >
                    <p style={{ marginBottom: 0, color: 'white' }} className='td-list-styles'>{item.question}</p>
                  </AccordionSummary>
                  <AccordionDetails>
                    <p style={{ marginBottom: 0, textAlign: 'left' }} className='td-list-styles'>{item.answer}</p>
                  </AccordionDetails>
                </Accordion>
              ))
            ) : (
              <Typography>No content available.</Typography>
            )}
          </Box>
        </>
      )}
    </Box>
  );
};

export default FAQTabs;
