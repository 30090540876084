import React, { useState, useEffect } from 'react';
import './App.css';
import PrivacyPolicy from '../../PrivacyPolicy';
import TermAndConditon from '../../TermAndConditon';
import FAQTabs from './Faqs';
import axios from 'axios';
import { CircularProgress } from '@mui/material';
import moment from 'moment-timezone';
import { useNavigate } from 'react-router-dom';  // Import useNavigate from react-router-dom
import AccountSettingContact from './AccountSettingContact';

function CustomerService() {
  const [activeMenu, setActiveMenu] = useState('Notifications');
  const [selectedNotification, setSelectedNotification] = useState(null);
  const [isLoading, setIsLoading] = useState (false);
  const [notifications, setNotifications] = useState([]);
 console.log("asdasda",typeof null)
  useEffect(() => {
    window.scrollTo(0, 0); 
  }, [activeMenu]); 

    useEffect(() => {
      const fetchCategories = async () => {
        setIsLoading(true);
        try {
          const response = await axios.get('https://backend.mysecretlit.com/api/user/getAllNotifications');

          // const notificationsArray = response.data.data.reverse().map(item => item.notification);

      setNotifications(response.data.data.reverse());
        } catch (error) {
          console.error('Error fetching categories:', error);
        } finally {
          setIsLoading(false);
        }
      };

      fetchCategories();
    }, []);

  const renderContent = () => {
    if (selectedNotification) {
      return <NotificationDetails notification={selectedNotification} onBack={() => setSelectedNotification(null)} />;
    }

    switch (activeMenu) {
      case 'Notifications':
        return <Notifications notifications={notifications} onSelect={setSelectedNotification} isLoading={isLoading} />;
      case 'FAQ':
        return <FAQTabs />;
      case 'QnA':
        return <PrivacyPolicy width={'100%'} />;
      case 'PreviousQueries':
        return <AccountSettingContact />;
      case 'TermsOfUse':
        return <TermAndConditon width={'100%'} />;
      default:
        return <Notifications notifications={notifications} onSelect={setSelectedNotification} isLoading={isLoading} />;
    }
  };

  return (
    <div className="main-div-bg-color">
      <div className="app-container">
        <Sidebar activeMenu={activeMenu} setActiveMenu={setActiveMenu} setSelectedNotification={setSelectedNotification} />
        <div className="main-content">
          {renderContent()}
        </div>
      </div>
    </div>
  );
}

const Sidebar = ({ activeMenu, setActiveMenu, setSelectedNotification }) => (
  <div className="sidebar">
    <div className="sidebar-first-div-notification">
      <div className="sidebar-title">Customer Service</div>
      <div className="sidebar-subtitle">Do you need help?</div>
    </div>
    <ul className="sidebar-menu">
      <li
        className={activeMenu === 'Notifications' ? 'activenotification' : ''}
        onClick={() => {
          setActiveMenu('Notifications');
          setSelectedNotification(null);
        }}
      >
        Notifications
      </li>
      <li
        className={activeMenu === 'FAQ' ? 'activenotification' : ''}
        onClick={() => {
          setActiveMenu('FAQ');
          setSelectedNotification(null);
        }}
      >
        FAQ
      </li>
      <li
        className={activeMenu === 'QnA' ? 'activenotification' : ''}
        onClick={() => {
          setActiveMenu('QnA');
          setSelectedNotification(null);
        }}
        data-hide-on-mobile
      >
        Privacy Policy
      </li>
      <li
        className={activeMenu === 'TermsOfUse' ? 'activenotification' : ''}
        onClick={() => {
          setActiveMenu('TermsOfUse');
          setSelectedNotification(null);
        }}
        data-hide-on-mobile
      >
        Terms of Use
      </li>
      <li
        className={activeMenu === 'PreviousQueries' ? 'activenotification' : ''}
        onClick={() => {
          setActiveMenu('PreviousQueries');
          setSelectedNotification(null);
        }}
      >
        Contact Us
      </li>
    </ul>
  </div>
);




const Notifications = ({ notifications, onSelect, isLoading }) => {
  const navigate = useNavigate(); // Initialize useNavigate hook
  const [userId , setuserId] = useState('')
  
  const storedData = localStorage.getItem('user');
  useEffect(() => {
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      const userId = parsedData.user?._id;
      setuserId(userId);
    } else {
      console.log('No data found in local storage.');
    }
  }, []);


  const handleNotificationClick = async (notification) => {
    console.log('notification',notification._id)
    // navigate(`?notificationId=${notification._id}&userId=${userId}`);
    onSelect(notification.notification);
  
      try {
        const response = await axios.post('https://backend.mysecretlit.com/api/user/notificationClick',{notificationId:notification._id});
        // Process the response data if needed
        console.log(response.data)
      } catch (error) {
        console.error('Error fetching notification details:', error);
      }
  
  };
  function formatDate(dateString) {
    // Server se milne wale date string ko JavaScript Date object mein convert karein
    const date = new Date(dateString);

    // Date ke components ko extract karein
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Month zero-based hota hai
    const year = date.getFullYear();

    // Date ko desired format mein convert karein
    return `${day}/${month}/${year}`;
}
  return (
    <div>
      <h2 className='h2-main-heading' style={{ paddingTop: 0, borderBottom: '1px solid white', width: '100%', height: '2.5em', display: 'flex', alignItems: 'center' }}>Notifications</h2>
      {isLoading ? (
        <div className="spinner-container">
          <CircularProgress color="error" />
        </div>
      ) : (
        <table>
          <thead>
            <tr>
              <th className='heading-main-notifcatin'>Number</th>
              <th className='heading-main-notifcatin'>Title</th>
              <th className='heading-main-notifcatin'>Publication date</th>
            </tr>
          </thead>
          <tbody>
            {notifications.map((notification, index) => (
              <tr key={notification.notification._id} onClick={() => handleNotificationClick(notification)}>
                <td className='td-list-styles'>{index + 1}</td>
                <td className='td-list-styles' style={{ textAlign: 'left' }}>{notification?.notification?.title}</td>
                <td className='td-list-styles'>{formatDate(notification?.notification?.date)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

const NotificationDetails = ({ notification, onBack }) => {
  function formatDate(dateString) {
    // Server se milne wale date string ko JavaScript Date object mein convert karein
    const date = new Date(dateString);

    // Date ke components ko extract karein
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Month zero-based hota hai
    const year = date.getFullYear();

    // Date ko desired format mein convert karein
    return `${day}/${month}/${year}`;
}

  return (
    <div>
      <h2 className='h2-main-heading' style={{ paddingTop: 0, borderBottom: '1px solid white', width: '100%', height: '2.5em', display: 'flex', alignItems: 'center' }}>Notifications</h2>
      <div className='row-notification-display'>
        <h5 className='h5-details-notification'>Title</h5>
        <p className='paragprg-details-notification'>{notification?.title}</p>
      </div>
      <div className='row-notification-display'>
        <h5 className='h5-details-notification'>Publication date</h5>
        <p className='paragprg-details-notification'>{formatDate(notification.date)}</p>
      </div>
      <div className='flex-direction-notifcation'>
      </div>
      <p className='notifcation-paragprh-content'>{notification?.description}</p>
      <div className='btn-notification-back'>
        <button className='btn-back-notifcation' onClick={onBack}>Back</button>
      </div>
    </div>
  );
};

export default CustomerService;
