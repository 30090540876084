import React, { useEffect, useState } from 'react';
import Carosel from '../components/caroselHome/CaroselHome';
import SliderCustom from '../components/BookCardtem/SliderCustom';
import Banner from './homesaleBanner/Banner';
import Responsive from '../components/BookCardtem/SlickSlider';
import axios from 'axios';
import { NavLink, useNavigate } from 'react-router-dom';
import './all.css';
import { Spinner } from 'react-bootstrap';
import RecentReadSlider from '../components/recentRead/RecentRead';
import RecentReadResponsive from '../components/recentRead/RecentReadResponsive';

const Home = () => {
  const [thiller, setThiller] = useState([]);
  const [bannerData, setBannerData] = useState([]);
  const [recentRead, setRecentRead] = useState([]);
  const checktoken = localStorage.getItem('token');
  // Separate loading states
  const [thillerLoading, setThillerLoading] = useState(true);
  const [bannerLoading, setBannerLoading] = useState(true);
  const [recentReadLoading, setRecentReadLoading] = useState(true);
  
  const navigate = useNavigate();

  const fetchRecentReadBooks = async () => {
    try {
      const response = await axios.get('https://backend.mysecretlit.com/api/user/getReadBooks', {
        headers: {
          Authorization: `Bearer ${checktoken}`,
        },
      });
      const readBooks = response.data.data.readHistory.reverse() || [];
      setRecentRead(readBooks);
    } catch (error) {
      console.log('Error fetching recent read books:', error);
    } finally {
      setRecentReadLoading(false); // Loading is complete
    }
  };

  const fetchHomeData = async () => {
    try {
      // Fetch Banners
      const bannerResponse = await axios.get('https://backend.mysecretlit.com/api/getHomeBanners');
      const banners = bannerResponse.data.data.reverse();
      setBannerData(banners);
    } catch (error) {
      console.error('Error fetching banner data:', error);
    } finally {
      setBannerLoading(false); // Loading is complete
    }

    try {
      // Fetch Thiller Genre
      const thillerResponse = await axios.get('https://backend.mysecretlit.com/api/user/getHomeGenre');
      const homeGenre = thillerResponse.data.data;
      setThiller(homeGenre);
    } catch (error) {
      console.error('Error fetching thriller genre:', error);
    } finally {
      setThillerLoading(false); // Loading is complete
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    // Fetch data on component mount
    fetchRecentReadBooks();
    fetchHomeData();
  }, []);
  const fetchCategoryData = async (categoryId) => {
    try {
      await axios.post('https://backend.mysecretlit.com/api/user/genreClick', { categoryId });
    } catch (error) {
      console.error('Error fetching category data:', error);
    }
  };
  const handleCategoryClick = (category) => {
    fetchCategoryData(category._id);
    const encodedCategory = encodeURIComponent(JSON.stringify(category));
    navigate(`/Genres/${category.genreName}?datacat=${encodedCategory}`);
    
  };
  return (
    <div style={{ width: '100%', background: 'black' }}>
       <Carosel Bannerdata={bannerData} />

      <div className='homemain-div-offer'>
        <div className='bannerhome-div'>
          <Banner cursor="true" handleFunction={() => navigate('/get-plans')} />
        </div>

        <div className="recent-view-div">
          {recentReadLoading ? (
            <div className="iconDiv"></div>
          ) : recentRead.length === 0 ? (
            <div className="iconDiv" />
          ) : (
            <div>
              <div className="iconDiv">
                <NavLink to='/mypage/RecentRead' style={{textDecoration:'none'}}>
                <h1 className="recenredRed-heading">Recently Read</h1>
                </NavLink>
               
              </div>
              {recentRead?.length <= 3 ? (
                <RecentReadResponsive showDeleteIcon={true} loading={recentReadLoading} CardDetails={recentRead} />
              ) : (
                <RecentReadSlider showDeleteIcon={true} loading={recentReadLoading} CardDetails={recentRead} />
              )}
            </div>
          )}
        </div>

        <div>
          {/* {thillerLoading ? (
            <div><Spinner animation="border" variant="light"/></div>
          ) : (
            thiller?.map((item) => (
              <div key={item.id}>
                <h2 className='h2-main-heading'>{item.genreName}</h2>
                {item.books.length <= 3 ? (
        <Responsive
          showDeleteIcon={false}
          loading={recentReadLoading}
          CardDetails={item.books}
        />
      ) : (
        <SliderCustom
          loading={thillerLoading}
          CardDetails={item.books}
        />
      )}
              </div>
            ))
          )} */}
          {thillerLoading ? (
  <div><Spinner animation="border" variant="light" /></div>
) : (
  thiller?.map((item) => (
    <div key={item.id}>
      {/* Add an onClick to handle category click */}
      <h2 
        className='h2-main-heading' 
        onClick={() => handleCategoryClick(item)} // Click handler
        style={{ cursor: 'pointer' }} // Add pointer to show it's clickable
      >
        {item.genreName}
      </h2>

      {item.books.length <= 3 ? (
        <Responsive
          showDeleteIcon={false}
          loading={recentReadLoading}
          CardDetails={item.books}
        />
      ) : (
        <SliderCustom
          loading={thillerLoading}
          CardDetails={item.books}
        />
      )}
    </div>
  ))
)}

        </div>
      </div>
    </div>
  );
};

export default Home;
