import React, { useState, useEffect } from 'react'
import BookCardTem from '../components/BookCardtem/BookCardTem'
import './all.css'
import { Pagination } from '@mui/material'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'


const NewPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 18;
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const naviagte = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    const now = new Date().toISOString();
    localStorage.setItem('lastVisitNewPage', now);
  }, []);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get('https://backend.mysecretlit.com/api/book/getBooks?type=book');
      setData(response.data?.data.reverse().slice(0,60));
     
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data?.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    window.scrollTo(0, 0);
  };

  return (
    <div style={{ width: '100%', background: 'black' }}>
      <div className='main-series-div-width'>
        <h2 className='h2-main-heading'>Newly Added</h2>
        <div className='seriesdiv-row'>
             {(isLoading ? Array.from(new Array(18)) : currentItems)?.map((card, index) => (
            <div key={index} style={{cursor:'pointer'}} className='custom-deatils-card' onClick={ () => naviagte('/BookDetails', { state: { data: card } })}>
            <BookCardTem
              Bookname={card?.title}
              firstTopTag={card?.category?.name}
              secTopTag={card?.SecTopTag}
              bookcategoriesFirst={card?.category?.name}
              bookcategoriesSec={card?.secondaryCategory?.name}
              rating={card?.rating}
              bookImg={card?.coverImage}
              loading={isLoading}
            />
          </div>
        ))}
        </div>
        <Pagination
          count={Math.ceil(data.length / itemsPerPage)}
          page={currentPage}
          onChange={handlePageChange}
          variant="outlined"
        />
      </div>
    </div>
  )
}

export default NewPage
