import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Pagination } from '@mui/material';
import BookCardTem from '../components/BookCardtem/BookCardTem';
import { Oval } from 'react-loader-spinner'; // Import the spinner component
import './all.css';

function Genres() {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [books, setBooks] = useState([]); // Initialize as an empty array
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true); // Add loading state
  const [isLoading ,setisLoading] =useState(true)
  const itemsPerPage = 48;
  const navigate = useNavigate();
  const fetchCategories = async () => {
    try {
      const response = await axios.get('https://backend.mysecretlit.com/api/user/getGenres');
      const formattedData = response.data.data.map((item) => ({
        ...item,
        id: item._id // Use '_id' or another unique property as 'id'
      }));
      setCategories(formattedData);
      if (formattedData.length > 0) {
        setSelectedCategory(formattedData[0]);
      }
      setLoading(false); // Set loading to false after data is fetched
    } catch (error) {
      console.error('Error fetching categories:', error);
      setLoading(false); // Set loading to false in case of error
    }
  };

  const fetchBooksByCategory = async (categoryId) => {
    try {
      setisLoading(true); // Set loading to true before fetching books
      const response = await axios.get(`https://backend.mysecretlit.com/api/user/getBookByCategory?categoryId=${categoryId}`);
      setBooks(response.data || []); // Ensure books is an array
     
    } catch (error) {
      console.error('Error fetching books:', error);
      // Set loading to false in case of error
    }finally{
      setisLoading(false);
    }
  };

  const handleBookClick = (bookData) => {
    navigate('/BookDetails', { state: { data: bookData } });
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  useEffect(() => {
    if (selectedCategory) {
      fetchBooksByCategory(selectedCategory.id);
    }
  }, [selectedCategory]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    window.scrollTo(0, 0);
  };

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    setCurrentPage(1); // Reset to first page on category change
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = books.length > 0 ? books.slice(indexOfFirstItem, indexOfLastItem) : [];

  return (
    <div>
      {loading ? (
        // Display spinner when loading is true
        <div className="spinner-container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh',background:'black'}}>
          <Oval
            height={80}
            width={80}
            color="red"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel='oval-loading'
            secondaryColor="white"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      ) : (
        // Display content when loading is false
        <>
          <ul className='ul-classname'>
            {categories?.map((category) => (
              <li key={category.id}>
                <NavLink
                  className={`category-link ${category.id === selectedCategory?.id ? 'activee' : 'noactivenavbar'}`}
                  onClick={() => handleCategoryClick(category)}
                >
                  {category.name}
                </NavLink>
              </li>
            ))}
          </ul>
          {selectedCategory && (
            <div style={{ background: 'black' }}>
              <div className='seriesdiv-row'>
              {(isLoading ? Array.from(new Array(18)) : currentItems)?.map((card, index) => (
            <div key={index} style={{cursor:'pointer'}} className='custom-deatils-card' onClick={ () => navigate('/BookDetails', { state: { data: card } })}>
            <BookCardTem
              Bookname={card?.bookTitle}
              firstTopTag={card?.FirstTopTag}
              secTopTag={card?.SecTopTag}
              bookcategoriesFirst={card?.primaryCategory?.name}
              bookcategoriesSec={card?.secondaryCategory?.name}
              rating={card?.rating}
              bookImg={card?.bookCoverImage?.url}
              loading={isLoading}
            />
          </div>
        ))}
              </div>
              <Pagination
                count={Math.ceil((books.length || 0) / itemsPerPage)}
                page={currentPage}
                onChange={handlePageChange}
                variant="outlined"
              />
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default Genres;

