import React, { useEffect, useState } from 'react'
import './Account.css'
import Form from 'react-bootstrap/Form';
import { IoIosArrowDown } from "react-icons/io";
import { useNavigate } from 'react-router-dom';
import { ImEye ,ImEyeBlocked} from "react-icons/im";
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import { CircularProgress } from '@mui/material';

const AccountSetting = () => {
    const [prepassword, setprepassword] = useState('');
    const [loading, setLoading] = useState(false); // State for loader
    const [repassword, setrepassword] = useState('');
    const [confrompassword, setconfrompassword] = useState('');
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [showPrepassword, setShowPrepassword] = useState(false);
    const [showRepassword, setShowRepassword] = useState(false);
    const [userId,setuserId] =useState('')
    const [plandata,setplandata] = useState('')
    const [showConfrompassword, setShowConfrompassword] = useState(false);
    const navigation = useNavigate()
    const [checkplan,setcheckplan] = useState()
    const togglePrepasswordVisibility = () => setShowPrepassword(!showPrepassword);
    const toggleRepasswordVisibility = () => setShowRepassword(!showRepassword);
    const toggleConfrompasswordVisibility = () => setShowConfrompassword(!showConfrompassword);
    const handleInputChange = (event) => {
        const value = event.target.value.trim();
        setprepassword(value);
        updateButtonState(value, repassword, confrompassword);
      };
      useEffect(() => {
        const storedData = localStorage.getItem('user');
        if (storedData) {
            try {
                const parsedData = JSON.parse(storedData);
                if (parsedData && parsedData.user && parsedData.user._id) {
                    setuserId(parsedData.user._id);
                    console.log(parsedData.user._id);
                } else {
                    console.error('User data or _id is missing');
                }
            } catch (error) {
                console.error('Error parsing user data:', error);
            }
        } else {
            console.log('No data found in local storage.');
        }
    }, []);
    
const fetchBooksByCategory = async () => {
  if (!userId) return;
  try {
      const response = await axios.get(`https://new-app-testing-2d30280db142.herokuapp.com/api/payments/get-all-remaning-dowloads/${userId}`);
      
      setplandata(response.data.currentPlan)
  } catch (error) {
      console.error('Error fetching books:', error);
  }
};
const fetchBookscheckPayment= async () => {
  if (!userId) return;
  try {
      const response = await axios.get(`https://new-app-testing-2d30280db142.herokuapp.com/api/check-payment-plan/${userId}`);
      console.log('response',response.data)
      setcheckplan(response.data)
      // setplandata(response.data.currentPlan)
  } catch (error) {
      console.error('Error fetching books:', error);
  }
};

useEffect(() => {
  fetchBooksByCategory();
  fetchBookscheckPayment()
}, [userId]);
const handlePasswordUpdate = () => {
  const checktoken = localStorage.getItem('token');
  // Validate passwords
  if (repassword !== confrompassword) {
    toast.error('Passwords do not match');
    return;
  }

  // Prepare payload for API
  const payload = {
    oldPassword:prepassword,
    newPassword:confrompassword,
  };

  // Show loader
  setLoading(true);

  // Example API call using Axios
  axios.patch('https://backend.mysecretlit.com/api/user/updatePassword', payload,{
    headers: {
      Authorization: `Bearer ${checktoken}`,
    },
  })
    .then(response => {
      toast.success('Password updated successfully');
      setprepassword('')
      setrepassword('')
      setconfrompassword('')
      // Optionally reset input fields or update UI
    })
    .catch(error => {
      console.error('Error updating password:', error);
      toast.error(error.response?.data?.error);
      // Handle error, show error toast or message
    })
    .finally(() => {
      setLoading(false); // Hide loader
    });
};
      const handleInputChangere = (event) => {
        const value = event.target.value.trim();
        setrepassword(value);
        updateButtonState(prepassword, value, confrompassword);
      };
    
      const handleInputChangeconform = (event) => {
        const value = event.target.value.trim();
        setconfrompassword(value);
        updateButtonState(prepassword, repassword, value);
      };
    
      const updateButtonState = (prepassword, repassword, confrompassword) => {
        const isFilled = prepassword.length > 0 && repassword.length > 0 && confrompassword.length > 0;
        const isMatch = repassword === confrompassword;
        setIsButtonDisabled(!(isFilled && isMatch));
      };
      useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      const buttonStyle = {
        backgroundColor: isButtonDisabled ? 'rgb(238, 45, 47)' : '#EE2D2F',
        color: 'white',
        cursor: isButtonDisabled ? 'defulat' : 'pointer',
        fontFamily: '"Noto Sans SC", sans-serif',
        padding: 5,
        width: '36%',
        borderRadius: 50,
        fontSize:14,
        border: 'none'
      };
      const buttonStyleGender = {
        backgroundColor: isButtonDisabled ? 'rgb(238, 45, 47)' : '#EE2D2F',
        color: 'white',
        cursor: isButtonDisabled ? 'defulat' : 'pointer',
        fontFamily: '"Noto Sans SC", sans-serif',
        padding: 5,
        width: '30%',
        borderRadius: 50,
        fontSize:14,
        border: 'none',
        marginTop:5
      };
  return (
    <div className='recentRead-main' style={{height:'auto'}}>
        <div className="rowdiv-recent">
        <h1 className="recenredRed-heading" >
        Account Setting
        </h1>
        <div className="infoaccount-sre">
            <h5 className="infodiv-heading" style={{paddingTop:0}}>Account Info</h5>
            <p className="paragraph-account-setting" style={{paddingBottom:10}}>Gender</p>
           <div className="slect-gender-div">
    <Form.Select aria-label="Default select example">
    
      <option value="1">Male</option>
      <option value="2">Female</option>
      <option value="2">Other</option>
    

    </Form.Select>
    
    <IoIosArrowDown className='icon-btn-gendrr'  />

    </div>
    <div className="buttonaccount-setting">
            {/* <ButtonCustom Text="Change Password"/> */}
            <button  onClick={handlePasswordUpdate} style={buttonStyleGender} disabled={isButtonDisabled}>
            {loading ? <CircularProgress size={20} style={{ color: 'white' }} /> : 'Update'}
            
            </button>
            </div>
            <h5 className="infodiv-heading">Change Password</h5>
            <div className="passwordDiv">
                <div className="com-pasword" > <p className="paragraph-account-setting" style={{color:'white',fontSize:'14px',fontWeight:'400'}}>Please enter current password</p></div>
                <div className="com-pasword newaddition"><input value={prepassword} onChange={handleInputChange}  type={showRepassword ? 'text' : 'password'} placeholder='Enter Old Password ' className='input-account-setting' /> <span style={{color:'black',position:'absolute',right: '15px'}} onClick={toggleRepasswordVisibility}>
                        {showRepassword ? <ImEye style={{color:'black',}} /> : <ImEyeBlocked style={{color:'black'}} />}
                    </span></div>
            </div>
            <div className="passwordDiv">
                <div className="com-pasword" > <p className="paragraph-account-setting" style={{color:'white',fontSize:'14px',fontWeight:'400'}}>Please enter new password</p></div>
                <div className="com-pasword newaddition"><input value={repassword} onChange={handleInputChangere}  type={showPrepassword ? 'text' : 'password'} placeholder='Enter New Password ' className='input-account-setting' />
                <span onClick={togglePrepasswordVisibility} style={{color:'black',position:'absolute',right: '15px'}}>
                        {showPrepassword ? <ImEye style={{color:'black',}} /> : <ImEyeBlocked style={{color:'black'}} />}
                    </span>
                </div>
            </div>
            <div className="passwordDiv">
                <div className="com-pasword" > <p className="paragraph-account-setting" style={{color:'white',fontSize:'14px',fontWeight:'400'}}>Please re-enter new password</p></div>
                <div className="com-pasword newaddition"><input value={confrompassword} onChange={handleInputChangeconform}  type={showConfrompassword ? 'text' : 'password'}  placeholder='Enter Confirm Password ' className='input-account-setting' />
                <span onClick={toggleConfrompasswordVisibility} style={{color:'black',position:'absolute',right: '15px'}}>
                        {showConfrompassword ? <ImEye style={{color:'black',}} /> : <ImEyeBlocked style={{color:'black'}} />}
                    </span>
                </div>
           
            </div>
            <ToastContainer
position="top-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="dark"
/>
            <div className="buttonaccount-setting">
            {/* <ButtonCustom Text="Change Password"/> */}
            <button onClick={handlePasswordUpdate} style={buttonStyle} disabled={isButtonDisabled}>
            {loading ? <CircularProgress size={20} style={{ color: 'white' }} /> : 'Change Password'}
            
            </button>
            </div>
            {checkplan?.paymentPlanId && checkplan?.paymentPlanId !== '' ? (
            <div className='div-plan-show'>
            <div className='total-book-downlood'>
            <h5 className="infodiv-heading" >Membership Plan  </h5>
            <h4 className='membership-active-plan-heading'>{plandata?.paymentPlan} Plan <span className='active-class-membership'>  (Active) </span> </h4>
            </div>
           
            <div className='plan-active-menbership'>
           
            <div className='main-plan-div-seleted'>
              <div className="total-book-downlood" style={{border:'none'}}>
                <h5 className="infodiv-heading size-additon">Books Downloads Available : <span style={{fontWeight:300,color:'white'}} > {plandata?.bookDownload} </span> </h5>
                <h5 className="infodiv-heading size-additon">AudioBooks Downloads Available : <span style={{fontWeight:300,color:'white'}} > {plandata?.audioBookDownload} </span> </h5>
              </div>
            </div>
            </div>
            <div className="passwordDiv" style={{marginTop:50,paddingBottom:30}}>
            <h5 className="infodiv-heading" style={{color:'white',fontSize:18,paddingTop:5 }}></h5>
            {checkplan && !checkplan.cancelMembership && (
                           <div className="btn-account-setting">
                           <button className='btn-account-sttig-btn' onClick={() => navigation('/cancelMembership')}>Cancel Membership </button>
                           </div>
                        )}
           
           <ToastContainer
position="top-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="dark"
/>
            </div>
            </div>) : (
        <p></p>
      )}
        </div>
      
        </div>
        
    </div>
  )
}

export default AccountSetting