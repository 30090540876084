  // import React, { useEffect, useRef, useState } from 'react';
  // import { getDocument, GlobalWorkerOptions } from 'pdfjs-dist/webpack';
  // import 'pdfjs-dist/web/pdf_viewer.css';
  // import { useLocation } from 'react-router-dom';
  // import { Spinner } from 'react-bootstrap';

  // GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${require('pdfjs-dist/package.json').version}/pdf.worker.min.js`;

  // const PDFViewer = ({ url }) => {
  //   const [bgColor, setBgColor] = useState('#ffffff');
  //   const [loading, setLoading] = useState(true); 
  //   const [numPages, setNumPages] = useState(0);
  //   const [error, setError] = useState(null);
  //   const canvasContainerRef = useRef(null);

  //   useEffect(() => {
  //     window.scrollTo(0, 0);
  //     if (!url) return; // Ensure url is available

  //     const loadingTask = getDocument(url);
  //     loadingTask.promise.then(pdf => {
  //       setNumPages(pdf.numPages);

  //       // Clear any existing canvases
  //       if (canvasContainerRef.current) {
  //         canvasContainerRef.current.innerHTML = '';
  //       }

  //       for (let pageNum = 1; pageNum <= pdf.numPages; pageNum++) {
  //         pdf.getPage(pageNum).then(page => {
  //           const viewport = page.getViewport({ scale: 1.5 });
  //           const canvas = document.createElement('canvas');
  //           const context = canvas.getContext('2d');

  //           canvas.height = viewport.height;
  //           canvas.width = viewport.width;
  //           canvas.style.backgroundColor = bgColor;
            
  //           if (canvasContainerRef.current) {
  //             canvasContainerRef.current.appendChild(canvas);
  //           }

  //           const renderContext = {
  //             canvasContext: context,
  //             viewport: viewport,
  //           };

  //           page.render(renderContext);
  //           setLoading(false);
  //         });
  //       }
  //     }).catch(error => {
  //       console.error('Error loading PDF:', error);
  //     });
  //   }, [url, bgColor]);

  //   const handleBgColorChange = (event) => {
  //     setBgColor(event.target.value);
  //   };

  //   return (
  //     <div>
  //       <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', background: 'black', minHeight: '100vh' }}>
  //       {loading ? (
  //         <Spinner animation="border" variant="light" /> // Spinner shown while loading
  //       ) : error ? (
  //         <div style={{ color: 'red' }}>{error}</div> // Display error message
  //       ) : (
  //         <div ref={canvasContainerRef} style={{ position: 'relative', width: '100%', overflow: 'auto' }} />
  //       )}
  //     </div>
  //       {/* <div ref={canvasContainerRef} style={{ position: 'relative', background: 'black' }} /> */}
  //     </div>
  //   );
  // };

  // const App = () => {
  //   // const location = useLocation();
  //   const data = localStorage.getItem('pdfurl')
  // console.log(' data', localStorage.getItem('pdfurl'))
  //   if (!data) {
  //     return <div>No PDF URL found in location state.sa</div>;
  //   }

  //   return (
  //     <div>
  //       <PDFViewer url={data} />
  //     </div>
  //   );
  // };

  // export default App;

  
  import React, { useEffect, useRef, useState } from 'react';
  import { getDocument, GlobalWorkerOptions } from 'pdfjs-dist/webpack';
  import 'pdfjs-dist/web/pdf_viewer.css';
  import { Spinner } from 'react-bootstrap';
  
  // Set the workerSrc to load the PDF.js worker
  GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${require('pdfjs-dist/package.json').version}/pdf.worker.min.js`;
  
  const PDFViewer = ({ url }) => {
    const [loading, setLoading] = useState(true); // Loading state
    const [numPages, setNumPages] = useState(0); // Number of pages in PDF
    const [error, setError] = useState(null); // Error state
    const canvasContainerRef = useRef(null); // Ref for the canvas container
    const renderedPages = useRef(new Set()); // Set to track rendered pages
  
    useEffect(() => {
      if (!url) return; // Ensure URL is provided
  
      const fetchPDF = async () => {
        try {
          const response = await fetch(url);
          if (!response.ok) {
            throw new Error(`Failed to fetch PDF: ${response.statusText}`);
          }
          const pdfData = await response.arrayBuffer();
          const loadingTask = getDocument({ data: pdfData });
          const pdf = await loadingTask.promise;
          setNumPages(pdf.numPages);
          setLoading(false); // Set loading to false when PDF is loaded
  
          // Clear existing canvases (optional, if needed)
          if (canvasContainerRef.current) {
            canvasContainerRef.current.innerHTML = '';
          }
  
          // Render each page (optimized to prevent duplicate rendering)
          for (let pageNum = 1; pageNum <= pdf.numPages; pageNum++) {
            if (renderedPages.current.has(pageNum)) {
              continue; // Skip rendering already rendered pages
              
            }
  
            const page = await pdf.getPage(pageNum);
            const viewport = page.getViewport({ scale: 1.4 });
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');
  
            canvas.height = viewport.height;
            canvas.width = viewport.width;   
  
  
            if (canvasContainerRef.current) {
              canvasContainerRef.current.appendChild(canvas);
            }
  
            const renderContext = {
              canvasContext: context,
              viewport: viewport,
            };
  
            await page.render(renderContext).promise;
            renderedPages.current.add(pageNum); // Mark page as rendered
          }
        } catch (err) {
          console.error('Error loading PDF:', err);
          setError('Failed to load PDF. Please check the URL or try again later.');
          setLoading(false); // Stop loading if there's an error
        }
      };
  
      fetchPDF();
    }, [url]);
  
    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', background: 'black', minHeight: '100vh' }}>
        {loading ? (
          <Spinner animation="border" variant="light" /> // Spinner shown while loading
        ) : error ? (
          <div style={{ color: 'red' }}>{error}</div> // Display error message
        ) : (
          <div ref={canvasContainerRef} style={{ position: 'relative', width: '100%', overflow: 'auto' }} />
        )}
      </div>
    );
  };
  
  // export default PDFViewer;
  
  
  const App = () => {
  
  const data = localStorage.getItem('pdfurl')

  // Log the data to verify it contains the expected URL
  console.log('Received data:', data);

  // For testing, replace this with your actual URL if location.state doesn't provide it
  const pdfUrl = data || "https://secret-life.s3.eu-north-1.amazonaws.com/files/1725628511037_Two_Twisted_Crowns.pdf";
useEffect(()=>{
  window.scrollTo(0, 0);
})
  if (!pdfUrl) {
    return <div>No PDF URL found in location state.</div>;
  }

  return (
    <div style={{ position: 'relative', background: 'black' }}>
      <PDFViewer url={data} />
    </div>
  );
};

export default App;
