import React, { useEffect } from 'react'
import './all.css'
import { NavLink } from 'react-router-dom';

const TermAndConditon = ({width}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
}, []);
  return (
    <div className="booking-detils">
    <div className='termConditon-main' style={{width:width}}>
    <h1 className='discription-heading-h2 term'>Terms of Use </h1>
    <p className="term-and-condition">
    Introduction & Acceptance of Terms of Service
These Terms of Service("Terms") will govern the use of My Secret Lit service ("Service") provided by the following company:
IB SOLUTIONS located at Teleport tower, Amsterdam, (all hereinafter referred to as “MSL”, "the company”, “we”, “us”, “our”), by all users("Users", "you", "your") including members("Member”) and guests("Guest").
    </p>
    <p className="term-and-condition" style={{paddingTop:0}}>
    By using our Service, Users agree to our Terms <NavLink to='/term-and-condition' style={{color:'rgb(13 103 237)',backgroundColor:'black',textDecoration:"underline"}}> (https://MySecretLit/Terms-Of-Use) </NavLink> , our Privacy Policy <NavLink to='/privacy-policy'> (https://MySecretLit/Privacy-Policy) </NavLink> , and any other policy, rules or codes of conduct relating to the Users' use of our Service. Please read our Terms and Privacy Policy before using our Service, if you do not consent to our Terms and Privacy Policy, you must stop using our Service.
    </p>
    <p className="term-and-condition" style={{paddingTop:0}}>
   <span style={{fontWeight:500}}> We may at any time, and at our discretion, revise these Terms and Privacy Policy. We will notify you of major revisions to these Terms and/or Privacy Policy through email and updates on Terms of Use and our Privacy Policy page.</span> It is your responsibility to review the newest versions of Terms and Privacy Policy.
    </p>
    <h6 className="tag-term">Service</h6>
    <p className="term-and-condition" style={{paddingTop:0}}>
    When creating an account with an e-mail address, you must use your own valid e-mail address. When creating an account with a social account, you must use your own social account. If you use an invalid e-mail address to create an account, intentionally or unintentionally, you are responsible for not receiving important information such as company news, service use, and etc.
    </p>
    <p className="term-and-condition" style={{paddingTop:0}}>
    You must be at least Sixteen (16) years old to use the Service. You must affirm to us that you are either more than Sixteen (16) years old, or an emancipated minor, or possess legal parental or guardian consent, and are fully able and competent to enter into the terms mentioned in our Terms. If you are under thirteen (13) years old, please do not use the Service.
    </p> 
    <p className="term-and-condition" style={{paddingTop:0}}>
    Recurring Payment (Members): Membership is automatically charged to your Payment Method every month. Membership allows you to view, without limits, the contents designated by us for different plan members.
For members, unless you notify us, and follow the process to cancel your membership before your monthly renewal date, you authorise us to charge your monthly membership fee to your Payment Method.
    </p> 
    <p className="term-and-condition" style={{paddingTop:0}}>
    "Duration of Service" due to cancellation of "Recurring Payment" will be the exact number of months you selected on your plan of membership page (https://MySecretLit/My-Payment-Plan) from the purchase date. When you cancel your "Recurring Payment", you can use the Service for the remainder of "Duration of Service" excluding the days you have already used. Once your "Duration of Service" is terminated, you will not be automatically charged.
    </p>  
    <p className="term-and-condition" style={{paddingTop:0}}>
    If a payment is not successfully settled on the billing date, due to expiration, insufficient funds, or otherwise, you can attempt different Payment Method even after the billing date. However, if the payment is not successfully settled for a long period of time, we may suspend your membership.
    </p> 
    <p className="term-and-condition" style={{paddingTop:0}}>
    Linked Account And Use :
    </p> 
    <p className="term-and-condition" style={{paddingTop:0}}>
    1. Email or SNS accounts can be linked to only one device account at a time.
    </p> 
    <p className="term-and-condition" style={{paddingTop:0}}>
    2. You can use your membership plans to read unlimited books online using your WEB account on other devices through linked accounts. However, books downloaded can only be read on the device downloaded and re-download of same book shall be deducted from your plan quota.
    </p> 
    <p className="term-and-condition" style={{paddingTop:0}}>
    3. Content can be viewed on other devices through linked accounts. However, if the linked account is terminated, the right to use is no longer available.
    </p> 
    <h6 className="tag-term">General</h6>
    <p className="term-and-condition" style={{paddingTop:0}}>
    We do not share personal information with external parties without prior consent from users unless when it is required by law.
    </p> 
    <p className="term-and-condition" style={{paddingTop:0}}>
    We are not liable in any way for the disclosure of personal information caused by you.    </p> 
    <p className="term-and-condition" style={{paddingTop:0}}>
    You are responsible for maintaining the confidentiality of the password and username. You must not allow a third party to access your account.
    </p> 
    <p className="term-and-condition" style={{paddingTop:0}}>
    You may not transfer or assign any access to the Service, and other rights or licenses granted to you hereunder without our consent.
    </p> 
    <p className="term-and-condition" style={{paddingTop:0}}>
    We may restrict your username in case there is a chance of leakage of personal information, or if you are considered antisocial or against the customs, or mistaken as an administrator.
    </p> 
    <p className="term-and-condition" style={{paddingTop:0}}>
    We may send you notifications or news to your e-mail address that you provided to us when you created the account.
    </p> 
    <p className="term-and-condition" style={{paddingTop:0}}>
    We may, for business or technical purposes, modify, amend, or change the Service in whole or in part.
    </p> 
    <p className="term-and-condition" style={{paddingTop:0}}>
    We may restrict or suspend the Service in whole or in part for the following instances.
    </p> 
    <p className="term-and-condition" style={{paddingTop:0}}>
    1. When installing, repairing, or inspecting the Service. <br></br>
    2. When facilities-based telecommunications business assigned by Telecommunications Business Act restricts the telecommunications service. <br></br>
    3. When power failure, equipment failure, or overflow of internet traffic restricts the Service. <br></br>
    4. When uncontrollable events such as natural disasters or national emergencies occur. <br></br>
    </p> 
    <p className="term-and-condition" style={{paddingTop:0}}>
    In case paid or free contents are not provided to you due to transition, failure, or merger of our business, we will notify you, take reasonable steps to stop any monetary damage incurred to you, and maintain the Service.
    </p> 
    <p className="term-and-condition" style={{paddingTop:0}}>
    When the contract between the content provider and us are terminated, the sale of the content may be suspended. 
    </p> 
    <p className="term-and-condition" style={{paddingTop:0}}>
    We may use cookies to collect your de-personalised information with your consent to generate statistical data to improve, develop, and operate the Service. You may control the use of cookies by changing the settings of your browser. However, when cookie storage is refused, your ability to use some features may be limited.
    </p>  
    <h6 className="tag-term"> Suspicious Activity</h6>
    <p className="term-and-condition" style={{paddingTop:0}}>
    In a case when a user performs any of the following activities, the user may be restricted of use from MySecretLit without any prior notice:
    </p> 
    <p className="term-and-condition" style={{paddingTop:0}}>
    1. Attempt to hack, or otherwise abuse any system. <br></br>
    2. Transfer, sell or acquire the rights of a paid Plan Membership to a third party illegally. <br></br>
    3. Transfer or sell downloaded data or modify any downloaded books for any purposes. <br></br>
    4. Falsification, crime tampering, theft, or act of fraudulent acquisition to the use of payment. <br></br>
    5. Web crawling MSL contents using any appliance, collecting and distributing with abnormal procedure. <br></br>
    6. Repetitive access to the site with irregular action. <br></br>
    7. Any act related to copyright infringement of MSL contents. 
    </p>
    <p className="term-and-condition" style={{paddingTop:0}}>
    If a user engages in any of the activities above, My Secret Lit reserves the right to permanently terminate the membership and/or reject any refund requests without any prior notice.
    </p>
    <h6 className="tag-term">Provision of Information & Advertisement</h6>
    <p className="term-and-condition" style={{paddingTop:0}}>
    We may post various information regarding the Service and advertisements on the Service, send e-mails, or Push Notification.
    </p>
    <h6 className="tag-term">Copyright of Contents</h6>
    <p className="term-and-condition" style={{paddingTop:0}}>
    You may not reproduce, distribute, rent, lease, sell, license, copy, modify or otherwise use the information you have obtained through our Service for profit. Any copyright infringement on the contents occurs, you are subjected to the related laws.
    </p>
    <h6 className="tag-term">Content Regulation</h6>
    <p className="term-and-condition" style={{paddingTop:0}}>
    1. All MySecretLit contents are protected under copyright law. Unauthorised use, sharing or duplication of MSL contents are strictly prohibited. <br></br>
    2. MySecretLit does not condone any organisation, or promotion of illegal contents. <br></br> 
    3. MySecretLit prohibits contents with any illegal activities involving minors, including but not limited to sexual activities involving underage children. <br></br>
    4. MySecretLit does not condone contents with illegal activities such as excessive violence or non-consensual sexual activities. The inclusion of such contents may lead to moderation, alteration, or removal upon identification and evaluation.
    </p>
    <h6 className="tag-term">"Obligations" of“MySecretLit”and the Users</h6>
    <p className="term-and-condition" style={{paddingTop:0,fontWeight:500}}>
    [Obligations of “MySecretLit”]
    </p>
    <p className="term-and-condition" style={{paddingTop:0,}}>
    We do not sell, rent or share personal information you provided to use the Service to external parties. However, We may share personal information when we are required to by law such as investigations by related authorities, or Communications Standards Commission.
    </p>
    <p className="term-and-condition" style={{paddingTop:0,}}>
    We maintain appropriate measures to resolve complaints of users regarding the Service as soon as possible.
    </p>
    <p className="term-and-condition" style={{paddingTop:0,}}>
    We take measures to maintain the Service at all times, and to reduce any inconvenience of users in using the Service.
    </p>
    <p className="term-and-condition" style={{paddingTop:0,fontWeight:500}}>
    [Obligations of "the Users"]
    </p>
    <p className="term-and-condition" style={{paddingTop:0,}}>
You must represent and warrant that you will not, in connection with your use of the Service, violate the following rules and if you do, you are solely responsible on all matters caused by your violation.
    </p>
    <p className="term-and-condition" style={{paddingTop:0,}}>
    1. Copying, or distributing any information or contents that you have obtained from our Service for profit without our prior consent. <br></br>
    2. Posting or distributing to the third party through e-mail or any other means anything that could infringe MySecretLit’, and others' patent, trademark, trade secret, copyright, and any other intellectual property. <br></br>
    3. Selling or transferring the account you created for the Service, or the account information to a third party. <br></br>
    4. Sending, posting, or distributing to the third party through e-mail or any other means any information, sentences, and shapes that could disturb the public order. <br></br>
    5. Collecting, saving, revealing other user's personal information without our prior consent. <br></br>
    6. Entering false information when creating or changing the account, illegally using other user's user id and password, impersonating to be others, or falsely stating your relationship with others. <br></br>
    7. Impersonating to be an employee or an administrator of MySecretLit’, or using others' names to post or send e-mails and messages. <br></br>
    8. Intentionally registering or distributing computer virus that could cause any malfunction of any equipment related to the Service or destroy information. <br></br>
    9. Sending information that could harm the Service, advertisements or Spam Mail against the receivers' clear refusal. <br></br>
    10. Sending any words, sounds, writings, pictures, or videos that could incur shame, hatred, or fear to others that could interrupt others' life. <br></br>
    11. Defaming or causing loss to others. <br></br>
    12. Accessing the weak points of the Service that was not fixed or missed by MySecretLit to access undisclosed information, to change or alter information. <br></br>
    13. Violating any laws or policies of the Service. <br></br>
    14. Obstructing the business of MySecretLit.
    </p> 
    <h6 className="tag-term">Privacy</h6>
    <p className="term-and-condition">
    We may collect information to improve the content quality and the Service. We take appropriate measures to secure the collected information.
    </p>
    <p className="term-and-condition" style={{paddingTop:0}}>
    All personal information of users is governed by related laws and Privacy Policy set forth by MySecretLit.
    </p>
    <h6 className="tag-term">Cancellation & Refund</h6>
    <p className="term-and-condition" style={{paddingTop:0,fontWeight:500}}>
    For Membership Plan Purchased
    </p>
    <p className="term-and-condition" style={{paddingTop:0}}>
    You may request a refund of your purchase of the Recurring Payment if
    </p>
    <p className="term-and-condition" style={{paddingTop:0}}>
    1. You request it within 24 hrs after the purchase; AND <br></br>
    2. You haven't downloaded any book or read/browse through 25% of the same or any other book currently available at the time you made the purchase within 1 day (calculated as 24 hours from the start of Membership); 
    </p>
    <p className="term-and-condition" style={{paddingTop:0}}>
    If you don't meet any of the conditions stated above, refunds are not available.
    </p>
    <p className="term-and-condition" style={{paddingTop:0,fontWeight:500}}>
    Limitation
    </p>
    <p className="term-and-condition" style={{paddingTop:0}}>
    Please note Any refund will be refunded in full through the same Payment Method you initially used.
    </p> 
    <h6 className="tag-term">Compensation Policy for Service Failure</h6>
    <p className="term-and-condition" style={{paddingTop:0}}>
    In case the Service is not available due to service failure, we will compensate for the lost time to users with Recurring Payment.
    </p> 
    <h6 className="tag-term">Indemnification</h6>
    <p className="term-and-condition" style={{paddingTop:0}}>
    You agree to indemnify MySecretLit against any losses, liabilities, claims, causes of action, and expenses (including reasonable attorneys' fees and expenses) arising out of or relating to your use of the Service, and your violation of these Terms or any applicable law or regulation. You will not be required to indemnify MySecretLit only when it did not cause any loss or unintentional.
    </p>
    <h6 className="tag-term">Limitation of Liability</h6>
    <p className="term-and-condition" style={{paddingTop:0}}>
    We intend to make our Service available at all times, however we are not liable when the Service is not available due to reasons beyond our control such as natural disasters, or change of law or administrative rules.
    </p> 
    <p className="term-and-condition" style={{paddingTop:0}}>
    We are not liable for service failures caused by you. However, you will not be liable if the cause was reasonable or out of your control.
    </p> 
    <p className="term-and-condition" style={{paddingTop:0}}>
    We are not liable for reliability or accuracy of any information, data, or facts posted on our Service, except when it was caused by gross negligence or intentionally done.
    </p> 
    <p className="term-and-condition" style={{paddingTop:0}}>
    We are not liable for any damages arising out of loss of profits, or loss caused by using data from our Service.
    </p> 
    <p className="term-and-condition" style={{paddingTop:0}}>
    We are not liable for any claim or dispute between you and another user, or a third party that arises in whole or in part from the Service.
    </p> 
    <h6 className="tag-term">Disputes</h6>
    <p className="term-and-condition" style={{paddingTop:0}}>
    We will take appropriate measures to resolve any issue, claim, or dispute between you and MySecretLit that arises in whole or in part from the Service. If the dispute between you and MySecretLit is not resolved, it will be submitted, commenced, and be heard in any “Dispute Resolution Law Firm” in Amsterdam. The laws of the country will govern any dispute of any sort that might arise between you and MySecretLit, except for the case when the unresolved issue, claim, or dispute between you and MySecretLit related or connected to your payment processed by concerned payment gateway. In this case, the resolution of your issue, claim, or dispute will be governed by the laws of the country payment gateway is operating in.
    </p> 
    <h6 className="tag-term">Contact Information</h6>
    <p className="term-and-condition" style={{paddingTop:0}}>
    In case if you have any questions regarding the Terms of Service, please e-mail us at contactus@Mysecretlit.com
    </p> 
    <p className="term-and-condition" style={{paddingTop:0}}>
    Provision
    </p>
    <p className="term-and-condition" style={{paddingTop:0}}>
    1. These Terms were last modified on July 24, 2024 <br></br>
    2. These terms and conditions are effective as of July 24, 2024, and the previous terms and conditions shall be superseded and replaced by these terms and conditions.
    </p>
    <h6 className="tag-term">Last Updated: July 24, 2024</h6>
   
    </div>
    </div>
  )
}

export default TermAndConditon