// import React from 'react'
// import './Banner.css'
// import { FaArrowRight } from "react-icons/fa6";
// import BannerImg from '../../assets/images/bannersales.png'

// import Animationbg from './Animationbg';
// const Banner = ({handleFunction, color = false, showIcon = true, cursor}) => {
//     const backgroundColor = color === true ? 'linear-gradient(148deg, rgb(0 0 0 / 70%) 69%, rgb(238, 45, 47) 65%)' : 'linear-gradient(148deg, rgb(0 0 0 / 70%) 69%, rgb(238, 45, 47) 65%)';
//     const cursorStyle = cursor === false ? 'default' : 'pointer';
//   return (
//    <div className={`main-banner-home ${cursor === 'none' ? 'no-cursor' : ''}`} onClick={handleFunction} style={{ background: backgroundColor, cursor: cursorStyle }}>
//     <div className="row-banner">
   
//         <div className="com-banners">
//             <div className="intersales">
//                 <img src={BannerImg} alt="" srcset="" className='bannerimage'/>
//             </div>
//             <div className="text">
//           <Animationbg/>
//     <div className="heading-main">
//     <span className="animation">50% DISCOUNT <span className="animation-next">ON YOUR MEMBERSHIP  <span className="animation additionsize" >AVAIL NOW!</span> </span> </span>
    
//     </div>
//             </div>
//         </div>
//         <div className="com-banners main-colms-banner" style={{}}>
//         <div className="textbutton">GET <br />  MEMBERSHIP</div>
//         {showIcon && (
//             <FaArrowRight
//             className='arrow-icon-banner'
//               onClick={handleFunction}
//             />
//           )}
//         </div>
//     </div>
   
//    </div>
//   )
// }

// export default Banner
import React from 'react';
import './Banner.css'; // Ensure this path is correct
import { FaArrowRight } from "react-icons/fa6";
import BannerImg from '../../assets/images/bannersales.png';
import Animationbg from './Animationbg';

const Banner = ({ handleFunction, color = false, showIcon = true, cursor }) => {
  const backgroundColor = color ? 'linear-gradient(148deg, rgb(0 0 0 / 70%) 69%, rgb(238, 45, 47) 65%)' : 'linear-gradient(148deg, rgb(0 0 0 / 70%) 69%, rgb(238, 45, 47) 65%)';
  const cursorStyle = cursor === 'none' ? 'default' : 'pointer';

  return (
    <div className={`main-banner-home ${cursor === 'none' ? 'no-cursor' : ''}`} onClick={handleFunction} style={{ background: backgroundColor, cursor: cursorStyle }}>
      <div className="row-banner">
        <div className="com-banners">
          <div className="intersales">
            <img src={BannerImg} alt="" className='bannerimage'/>
          </div>
          <div className="text">
          
            <Animationbg/>
            <div className="heading-main">
              <span className="animation">50% DISCOUNT <span className="animation-next">ON YOUR MEMBERSHIP <span className="animation additionsize">AVAIL NOW!</span></span></span>
            </div>
          </div>
        </div>
        <div className="com-banners main-colms-banner">
          <div className="textbutton">GET <br /> MEMBERSHIP</div>
          {showIcon && (
            <FaArrowRight className='arrow-icon-banner' onClick={handleFunction}/>
          )}
        </div>
      </div>
    </div>
  );
};

export default Banner;
