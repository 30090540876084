import React from 'react';
import './Banner.css'; // Ensure this path is correct

const Animationbg = () => {
  return (
    <div className="video-text-container">
      <video className="heading-video" src='https://media.istockphoto.com/id/906030022/video/burning-flame.mp4?s=mp4-640x640-is&k=20&c=v-5nUVLkb1MEwkzoYtM6xvl1ydL3MQLuxaxCt-d84t0=' autoPlay muted loop></video>
      <h1 className="heading-top">
        Limited Time Offer
      </h1>
    </div>
  );
};

export default Animationbg;
