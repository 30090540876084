// import React, { useState, useEffect } from "react";
// import Slider from "react-slick";
// import BookCardTem from "./BookCardTem";
// import { useNavigate } from 'react-router-dom';
// import axios from "axios";
// import { MdDelete } from "react-icons/md";
// import { Dialog, DialogActions, DialogContent,} from "@mui/material";
// const AutoPlay = ({ CardDetails, loading,showDeleteIcon }) => {
//   const [userId, setuserId] = useState('');
//   const [openDialog, setOpenDialog] = useState(false);
//   const settings = {
//     dots: false,
//     infinite: false,
//     slidesToShow: 5.5,
//     slidesToScroll: 4,
//     cssEase: "linear",
//     centerMode: false,
//     responsive: [
//       {
//         breakpoint: 768,
//         settings: {
//           slidesToShow: 3.5,
//           slidesToScroll: 3,
//         }
//       },
//       {
//         breakpoint: 480,
//         settings: {
//           slidesToShow: 3.5,
//           slidesToScroll: 3,
//         }
//       }
//     ]
//   };
//   const navigate = useNavigate();
//   const storedData = localStorage.getItem('user');

//   useEffect(() => {
//     if (storedData) {
//       const parsedData = JSON.parse(storedData);
//       const userId = parsedData.user?._id;
//       setuserId(userId);
//     } else {
//       console.log('No data found in local storage.');
//     }
//   }, [storedData]);

//   const handlefav = async (bookData, isFav) => {
//     console.log(bookData._id);
//     try {
//       if (isFav && bookData.favoriteId) {
//         // If already favorite and favoriteId exists, delete it from favorites
//         const response = await axios.delete(`https://new-app-testing-2d30280db142.herokuapp.com/api/delete-favourite-book/${bookData.favoriteId}`, {
//           headers: {
//             'Content-Type': 'application/json'
//           },
//           data: { userId }
//         });
//         console.log("response", response,bookData.favoriteId);
  
//         if (response.data) {
//           console.log("Removed from favorites", response.data);
//         }
//       } else if (!isFav && !bookData.favoriteId) {
//         // Otherwise, add it to favorites if not already favorite and favoriteId is null
//         const payload = {
//           userId: userId,
//           bookId: bookData._id,
//           bookTitle: bookData?.bookTitle,
//           oblicAuthor: bookData?.oblicAuthor,
//           authorName: bookData?.authorName,
//           primaryCategory: bookData?.category?._id || 'Unknown',
//           secondaryCategory: bookData.secondaryCategory?._id || 'Unknown',
//           series: bookData.series,
//           shortDescription: bookData.shortDescription,
//           longDescription: bookData?.longDescription,
//           bookCoverImage: bookData?.bookCoverImage?.url,
//           pdfUpdate: bookData.pdfUpdate?.url,
//           epubUpload: bookData.epubUpload?.url,
//           kindleMobiUpload: bookData.kindleMobiUpload?.url,
//           rating: '2.4',
//           isFavourite: true
//         };
//         const response = await axios.post('https://new-app-testing-2d30280db142.herokuapp.com/api/create-favourite-book', payload, {
//           headers: {
//             'Content-Type': 'application/json'
//           }
//         });
//         if (response.data) {
//           console.log("Added to favorites", response.data);
//         }
//       } else {
//         console.log("No action needed");
//       }
//     } catch (error) {
//       if (error.response) {
//         console.log(error.response.data);
//       } else if (error.request) {
//         console.log('Server not responding');
//       } else {
//         console.log('Unexpected error occurred');
//       }
//     }
//   };
//   const handleBookClick = (bookData) => {
//     navigate('/BookDetails', { state: { data: bookData } });
//   };
//   const openRefundDialog = (event) => {
//     event.stopPropagation(); // Prevent the event from triggering navigation
//     setOpenDialog(true);
// };

// const closeRefundDialog = () => {
//     setOpenDialog(false);
// };

// const confirmRefund = async (id) => {
//   const checktoken = localStorage.getItem('token')
//   try {
//       const res = await axios.delete(`https://backend.mysecretlit.com/api/user/deleteReadBook?bookId=${id}`,{
//           headers: {
//               Authorization: `Bearer ${checktoken}`,
//             },
//       });
//       if (res.status === 200) {
//        window.location.reload()
//       } else {
//         console.log('Failed to delete book. Please try again.');
//       }
//     } catch (error) {
//       console.error('Error making API call:', error);
    
//     }
//   console.log('recendt' ,id)
//   closeRefundDialog();
// };
//   return (
//     <div className="slider-container">
//       <Slider {...settings}>
//         {(loading ? Array.from(new Array(5)) : CardDetails)?.map((card, index) => (
//           <div className="selectd-delete-btn" key={index} onClick={() => handleBookClick(card)} style={{ cursor: 'pointer' }}>
//           {showDeleteIcon && (
//                             <MdDelete
//                                 className="delete-icon-book"
//                                 onClick={(event) => openRefundDialog(event)} // Trigger the dialog on delete icon click
//                             />
//                         )}

//             <BookCardTem
//               Bookname={card?.bookTitle}
//               firstTopTag={card?.FirstTopTag}
//               secTopTag={card?.SecTopTag}
//               bookcategoriesFirst={card?.category?.name}
//               bookcategoriesSec={card?.secondaryCategory?.name}
//               rating={card?.rating}
//               bookImg={card?.coverImage}
//               loading={loading}
//               isFavorite={card?.isFavourite}
//               onHeartClick={() => handlefav(card, card?.isFavourite)} // Pass the handlefav function and isFavourite status
//             />
//              <Dialog
//              style={{padding:'13px 24px'}}
//                             open={openDialog}
//                             onClose={(event) => { event.stopPropagation(); closeRefundDialog(); }} // Prevent navigating when closing dialog
//                             aria-labelledby="refund-dialog-title"
//                             aria-describedby="refund-dialog-description"
//                         >

//                             <DialogContent>
//                                 <p className='reviewDiv poupparagrgtext'>
//                                 Are you sure you want to delete this book from Recently Read ?
//                                 </p>
//                             </DialogContent>
//                             <DialogActions style={{display:'flex',justifyContent:'center',paddingBottom:34}}>
//                             <button className="btn-all-delete-popup" onClick={(event) => { event.stopPropagation(); confirmRefund(card._id); }} color="secondary" autoFocus>
//                                     Delete
//                                 </button>
//                                 <button  className="btn-all-delete-popup" onClick={(event) => { event.stopPropagation(); closeRefundDialog(); }} color="primary">
//                                     Cancel
//                                 </button>
                                
//                             </DialogActions>
//                         </Dialog>
//           </div>
//         ))}
//       </Slider>
//     </div>
//   );
// }

// export default AutoPlay;
import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import BookCardTem from "./BookCardTem";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import { MdDelete } from "react-icons/md";
import { Dialog, DialogActions, DialogContent } from "@mui/material";

const AutoPlay = ({ CardDetails, loading, showDeleteIcon }) => {
  const [userId, setuserId] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [sortedBooks, setSortedBooks] = useState([]);
  
  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 5.5,
    slidesToScroll: 4,
    cssEase: "linear",
    centerMode: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3.5,
          slidesToScroll: 3,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3.5,
          slidesToScroll: 3,
        }
      }
    ]
  };

  const navigate = useNavigate();
  const storedData = localStorage.getItem('user');

  useEffect(() => {
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      const userId = parsedData.user?._id;
      setuserId(userId);
    } else {
      console.log('No data found in local storage.');
    }
  }, [storedData]);

  useEffect(() => {
    if (CardDetails) {
      const homeBannerBooks = CardDetails.filter(book => book.homeBanner);
      const otherBooks = CardDetails.filter(book => !book.homeBanner);
      setSortedBooks([...homeBannerBooks, ...otherBooks]);
    }
  }, [CardDetails]);

  const handlefav = async (bookData, isFav) => {
    try {
      if (isFav && bookData.favoriteId) {
        const response = await axios.delete(`https://new-app-testing-2d30280db142.herokuapp.com/api/delete-favourite-book/${bookData.favoriteId}`, {
          headers: {
            'Content-Type': 'application/json'
          },
          data: { userId }
        });
        if (response.data) {
          console.log("Removed from favorites", response.data);
        }
      } else if (!isFav && !bookData.favoriteId) {
        const payload = {
          userId: userId,
          bookId: bookData._id,
          bookTitle: bookData?.bookTitle,
          oblicAuthor: bookData?.oblicAuthor,
          authorName: bookData?.authorName,
          primaryCategory: bookData?.category?._id || 'Unknown',
          secondaryCategory: bookData.secondaryCategory?._id || 'Unknown',
          series: bookData.series,
          shortDescription: bookData.shortDescription,
          longDescription: bookData?.longDescription,
          bookCoverImage: bookData?.bookCoverImage?.url,
          pdfUpdate: bookData.pdfUpdate?.url,
          epubUpload: bookData.epubUpload?.url,
          kindleMobiUpload: bookData.kindleMobiUpload?.url,
          rating: '2.4',
          isFavourite: true
        };
        const response = await axios.post('https://new-app-testing-2d30280db142.herokuapp.com/api/create-favourite-book', payload, {
          headers: {
            'Content-Type': 'application/json'
          }
        });
        if (response.data) {
          console.log("Added to favorites", response.data);
        }
      }
    } catch (error) {
      if (error.response) {
        console.log(error.response.data);
      } else if (error.request) {
        console.log('Server not responding');
      } else {
        console.log('Unexpected error occurred');
      }
    }
  };

  const handleBookClick = (bookData) => {
    navigate('/BookDetails', { state: { data: bookData } });

  };

  const openRefundDialog = (event) => {
    event.stopPropagation();
    setOpenDialog(true);
  };

  const closeRefundDialog = () => {
    setOpenDialog(false);
  };

  const confirmRefund = async (id) => {
    const checktoken = localStorage.getItem('token');
    try {
      const res = await axios.delete(`https://backend.mysecretlit.com/api/user/deleteReadBook?bookId=${id}`, {
        headers: {
          Authorization: `Bearer ${checktoken}`,
        },
      });
      if (res.status === 200) {
        window.location.reload();
      } else {
        console.log('Failed to delete book. Please try again.');
      }
    } catch (error) {
      console.error('Error making API call:', error);
    }
    closeRefundDialog();
  };

  return (
    <div className="slider-container">
      <Slider {...settings}>
        {(loading ? Array.from(new Array(5)) : sortedBooks)?.map((card, index) => (
          <div className="selectd-delete-btn" key={index} onClick={() => handleBookClick(card)} style={{ cursor: 'pointer' }}>
            {showDeleteIcon && (
              <MdDelete
                className="delete-icon-book"
                onClick={(event) => openRefundDialog(event)}
              />
            )}
            <BookCardTem
              Bookname={card?.bookTitle}
              firstTopTag={card?.FirstTopTag}
              secTopTag={card?.SecTopTag}
              bookcategoriesFirst={card?.category?.name}
              bookcategoriesSec={card?.secondaryCategory?.name}
              rating={card?.rating}
              bookImg={card?.coverImage}
              loading={loading}
              isFavorite={card?.isFavourite}
              onHeartClick={() => handlefav(card, card?.isFavourite)}
            />
            <Dialog
              open={openDialog}
              onClose={(event) => { event.stopPropagation(); closeRefundDialog(); }}
              aria-labelledby="refund-dialog-title"
              aria-describedby="refund-dialog-description"
            >
              <DialogContent>
                <p className='reviewDiv poupparagrgtext'>
                  Are you sure you want to delete this book from recently read?
                </p>
              </DialogContent>
              <DialogActions style={{ display: 'flex', justifyContent: 'center', paddingBottom: 10 }}>
                <button className="btn-all-delete-popup" onClick={(event) => { event.stopPropagation(); confirmRefund(card._id); }} color="secondary" autoFocus>
                  Delete
                </button>
                <button className="btn-all-delete-popup" onClick={(event) => { event.stopPropagation(); closeRefundDialog(); }} color="primary">
                  Cancel
                </button>
              </DialogActions>
            </Dialog>
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default AutoPlay;
