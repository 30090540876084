// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import {CircularProgress} from '@mui/material';
// import { useNavigate } from 'react-router-dom';
// const PromoCodes = () => {
//   const [inputValue, setInputValue] = useState('');
//   const [isButtonDisabled, setIsButtonDisabled] = useState(true);
//   const [loading, setLoading] = useState(false);
//   const naviagtion = useNavigate()
//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, []);
//   const handleInputChange = (event) => {
//     const value = event.target.value.trim();
//     setInputValue(value);
//     setIsButtonDisabled(value === '');
//   };

//   const handleApplyPromoCode = async () => {
//     if (inputValue.trim() === '') {
//       toast.error('Please enter a promo code.');
//       return;
//     }
//     setLoading(true);
//     try {
//       const response = await axios.get(`https://new-app-testing-2d30280db142.herokuapp.com/api/book/search-coupon-code?couponCodeName=${encodeURIComponent(inputValue)}`);
//       console.log('Response:', response);
//       if (response.data && response.data.success) {
//         localStorage.setItem('promoCodeDiscount', JSON.stringify(response.data));
//         toast.success('Promo code applied successfully');
//       } else {
//         toast.error(response.data.message || 'Promo code not found');
//       }
//       setInputValue('');
//       setIsButtonDisabled(true);
//     } catch (error) {
//       console.error('Error:', error);
//       if (error.response) {
//         toast.error(error.response.data.message || 'Invalid request');
//       } else if (error.request) {
//         toast.error('Server not responding');
//       } else {
//         toast.error('Unexpected error occurred');
//       }
//     } finally {
//       setLoading(false);
//     }
//   };
  

//   const buttonStyle = {
//     backgroundColor: isButtonDisabled ? 'rgb(217 82 84)' : '#EE2D2F',
//     color: 'white',
//     cursor: isButtonDisabled ? 'default' : 'pointer',
//     fontFamily: '"Noto Sans SC", sans-serif',
//     padding: 5,
//     borderRadius: 50,
//     border: 'none'
//   };

//   return (
//     <div className='recentRead-main' style={{ height: 'auto' }}>
//       <div className="rowdiv-recent">
//         <h1 className="recenredRed-heading" style={{ paddingBottom: 0, height: 'height: 26px', background: 'black' }}>Apply Promo Codes</h1>
//         <div className='padding-responsive-promo-code'>
//           <p className="paragraph-account-setting settingTop" style={{ textAlign: 'left' }}> Please type in your promo code </p>
//           <div className="passwordDiv gap">
//             <div className="promocodeDiv">
//               <input
//                 name='Promocode'
//                 type="text"
//                 value={inputValue}
//                 onChange={handleInputChange}
//                 placeholder='Promo Code'
//                 className='input-account-setting'
//                 style={{ width: '100%' }}
//               />
//             </div>
//             <div className="promocodeDiv-first">
//             <button
//             className='button-promocode-responsvie'
//                 style={buttonStyle}
//                 onClick={handleApplyPromoCode}
//                 disabled={isButtonDisabled}
//               >
//                 {loading ? <CircularProgress size={20} style={{ color: 'white' }} /> : 'Apply'}
//               </button>
//             </div>
//           </div>
//           {/* {loading && (
//             <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
//               <CircularProgress />
//             </div>
//           )} */}
//         </div>
//         <div style={{ padding: '0px 20px 30px 20px' }}>
//           <h4 className="promocodeHeading">Precautions when using promo code</h4>
//           <p className="paragraph-account-setting" style={{ textAlign: 'left' }}>- Promotion codes are not applicable for users with active membership. </p>
//           <p className="paragraph-account-setting" style={{ textAlign: 'left', paddingTop: 0 }}>- Benefits can vary according to which promo code you use. </p>
//           <p className="paragraph-account-setting" style={{ textAlign: 'left', paddingTop: 0 }}>- You cannot use expired promo codes. </p>
//           <p className="paragraph-account-setting" style={{ textAlign: 'left', paddingTop: 0 }}>- Promo code can only be used once for one account. </p>
//           <p className="paragraph-account-setting" style={{ textAlign: 'left', paddingTop: 0 }}>- Promo code registrations and usage policy may change according to MySecretLit policies. </p>
//           <p className="paragraph-account-setting" style={{ textAlign: 'left', paddingTop: 0 }}>- Thank you! </p>
//         </div>
//       </div>
//       <ToastContainer
//         position="top-center"
//         autoClose={3000}
//         hideProgressBar={false}
//         newestOnTop={false}
//         closeOnClick
//         rtl={false}
//         pauseOnFocusLoss
//         draggable
//         pauseOnHover
//       />
//     </div>
//   );
// };

// export default PromoCodes;
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const PromoCodes = () => {
  const [inputValue, setInputValue] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
 console.log('tokenchecnk', localStorage.getItem('promoCodeDiscount'))
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleInputChange = (event) => {
    const value = event.target.value.trim();
    setInputValue(value);
    setIsButtonDisabled(value === '');
  };

  const handleApplyPromoCode = async () => {
    if (inputValue.trim() === '') {
      toast.error('Please enter a promo code.');
      return;
    }
    setLoading(true);
    const checktoken = localStorage.getItem('token');
    try {
      const response = await axios.get(
        `https://backend.mysecretlit.com/api/verifyPromoCode?promoCode=${encodeURIComponent(inputValue)}`,
        {
          headers: {
            Authorization: `Bearer ${checktoken}`,
          },
        }
      );
  
      if (response.data && response.data.status) {
        const promoData = {
          discount: response.data.data.discount,
          startDate: response.data.data.startDate,
          endDate: response.data.data.endDate,
          isActive: response.data.data.isActive,
          name: response.data.data.name,
        };
  
        // Save promoData in localStorage
        localStorage.setItem('promoCodeDiscount', JSON.stringify(promoData));
        // navigate('/get-plans')
        toast.success('Promo code applied successfully');
        setTimeout(()=> {navigate('/get-plans')},[1000])
      } else {
        toast.error(response.data.message || 'Promo code not found');
      }
      setInputValue('');
      setIsButtonDisabled(true);
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.message || 'Invalid request');
      } else if (error.request) {
        toast.error('Server not responding');
      } else {
        toast.error('Unexpected error occurred');
      }
    } finally {
      setLoading(false);
    }
  };
  

  const buttonStyle = {
    backgroundColor: isButtonDisabled ? 'rgb(217 82 84)' : '#EE2D2F',
    color: 'white',
    cursor: isButtonDisabled ? 'default' : 'pointer',
    fontFamily: '"Noto Sans SC", sans-serif',
    padding: 5,
    borderRadius: 50,
    border: 'none'
  };

  return (
    <div className='recentRead-main' style={{ height: 'auto' }}>
      <div className="rowdiv-recent">
        <h1 className="recenredRed-heading" style={{ paddingBottom: 0, height: '26px', background: 'black' }}>Apply Promo Codes</h1>
        <div className='padding-responsive-promo-code'>
          <p className="paragraph-account-setting settingTop" style={{ textAlign: 'left' }}> Please type in your promo code </p>
          <div className="passwordDiv gap">
            <div className="promocodeDiv">
              <input
                name='Promocode'
                type="text"
                value={inputValue}
                onChange={handleInputChange}
                placeholder='Promo Code'
                className='input-account-setting'
                style={{ width: '100%' }}
              />
            </div>
            <div className="promocodeDiv-first">
              <button
                className='button-promocode-responsvie'
                style={buttonStyle}
                onClick={handleApplyPromoCode}
                disabled={isButtonDisabled}
              >
                {loading ? <CircularProgress size={20} style={{ color: 'white' }} /> : 'Apply'}
              </button>
            </div>
          </div>
        </div>
        {/* <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      /> */}
        <div style={{ padding: '0px 20px 30px 20px' }}>
          <h4 className="promocodeHeading">Precautions when using promo code</h4>
          <p className="paragraph-account-setting" style={{ textAlign: 'left' }}>- Promotion codes are not applicable for users with active membership.</p>
          <p className="paragraph-account-setting" style={{ textAlign: 'left', paddingTop: 0 }}>- Benefits can vary according to which promo code you use.</p>
          <p className="paragraph-account-setting" style={{ textAlign: 'left', paddingTop: 0 }}>- You cannot use expired promo codes.</p>
          <p className="paragraph-account-setting" style={{ textAlign: 'left', paddingTop: 0 }}>- Promo code can only be used once for one account.</p>
          <p className="paragraph-account-setting" style={{ textAlign: 'left', paddingTop: 0 }}>- Promo code registrations and usage policy may change according to MySecretLit policies.</p>
          <p className="paragraph-account-setting" style={{ textAlign: 'left', paddingTop: 0 }}>- Thank you!</p>
        </div>
      </div>
      <ToastContainer
position="top-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="dark"
/>
    </div>
  );
};

export default PromoCodes;
