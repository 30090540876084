
import React, { useEffect, useState } from 'react';
import './Cardoption.css';
import VisaImg from '../../assets/images/visa.png';
import ExpressImg from '../../assets/images/american.png';
import MasterImg from '../../assets/images/master.png';
import ButtonCustom from '../../components/ButtonCustom';
import { NavLink, useLocation } from 'react-router-dom';
import { Checkbox, FormControlLabel } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

function CreditCardForm() {
    const navigate = useNavigate();
    const location = useLocation();
    const { data } = location.state || {};
    const [userId, setUserId] = useState('');
    const storedData = localStorage.getItem('user');

    console.log('location data:', data);

    useEffect(() => {
        if (storedData) {
            const parsedData = JSON.parse(storedData);
            // setUserId(parsedData.user._id);
            // console.log('User ID:', parsedData.user._id);
        } else {
            console.log('No user data found in local storage.');
        }
    }, [storedData]);

    const getPromoCodeDiscount = () => {
        const promoCodeDiscount = localStorage.getItem('promoCodeDiscount');
        if (promoCodeDiscount) {
            try {
                return JSON.parse(promoCodeDiscount);
            } catch (error) {
                console.error('Failed to parse promo code discount from localStorage:', error);
                localStorage.removeItem('promoCodeDiscount');
                return null;
            }
        }
        return null;
    };

    const promoCodeDiscountData = getPromoCodeDiscount();
    const promoCodeDiscount = promoCodeDiscountData ? parseFloat(promoCodeDiscountData.discount) : 0;

    const actualPrice = parseFloat(data?.price || data?.pricing) || 0;
    const cutPrice = parseFloat(data?.price) || 0;
    const discountAmount = promoCodeDiscount > 0 ? (data?.price * promoCodeDiscount) / 100 : 0;
    const discountedPrice = (cutPrice - discountAmount).toFixed(2);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [formData, setFormData] = useState({
        cardNumber: '',
        cardHolder: '',
        expiryDate: '',
        cvv: '',
        plan: data?._id,
        price: discountedPrice,
        agreement: false,
    });

    const [formErrors, setFormErrors] = useState({
        cardNumber: false,
        cardHolder: false,
        expiryDate: false,
        cvv: false,
        agreement: false,
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'expiryDate' && value.length === 2 && formData.expiryDate.length === 1) {
            setFormData({ ...formData, [name]: value + '/' });
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };

    const handleCardNumberChange = (e) => {
        const cardNumber = e.target.value;
        let cardType = '';
        let cardImage = '';

        if (cardNumber.startsWith('4')) {
            cardType = 'Visa';
            cardImage = VisaImg;
        } else if (cardNumber.startsWith('5')) {
            cardType = 'MasterCard';
            cardImage = MasterImg;
        } else if (cardNumber.startsWith('3')) {
            cardType = 'American Express';
            cardImage = ExpressImg;
        }

        let formattedCardNumber = cardNumber.replace(/\s+/g, '').replace(/(\d{4})/g, '$1 ').trim();

        setFormData({ ...formData, cardNumber: formattedCardNumber, cardType, cardImage });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        let errors = { ...formErrors };
        let valid = true;

        if (!formData.cardNumber) {
            toast.error("Please add a card number.");
            errors.cardNumber = true;
            valid = false;
        }

        if (!formData.cardHolder) {
            toast.error("Please add a cardHolder name.");
            errors.cardHolder = true;
            valid = false;
        }

        if (!formData.expiryDate) {
            toast.error("Please add expiryDate.");
            errors.expiryDate = true;
            valid = false;
        }

        if (!formData.cvv) {
            toast.error("Please add a CVV.");
            errors.cvv = true;
            valid = false;
        }

        if (!formData.agreement) {
            toast.error("Please accept the agreement.");
            errors.agreement = true;
            valid = false;
        }

        setFormErrors(errors);

        if (valid) {
            const payload = {
                userId: userId,
                paymentPlanId: data._id,
                cardDetails: {
                    cardNumber: formData.cardNumber,
                    nameOfCard: formData.cardHolder,
                    expiryDate: formData.expiryDate,
                    cvv: formData.cvv,
                },
                promoCode: promoCodeDiscountData ? promoCodeDiscountData.name : "",
            };
            try {
                const response = await axios.post('https://new-app-testing-2d30280db142.herokuapp.com/api/payments/buy-and-process-payment', payload);
                toast.success('Payment successful!');
                localStorage.removeItem('promoCodeDiscount');
                navigate('/');
                console.log('Payment response:', response.data.user);

            } catch (error) {
                toast.error('Payment failed. Please try again.');
                console.error('Payment error:', error);
            }
        }
    };

    return (
        <div className="booking-detils">
            <div className="main-div-detils addVip" style={{ background: 'none' }}>
                <div className="credit-card-form-container">
                    <h2 className='discription-heading-h2 getVip' style={{ color: 'white', textAlign: 'left' }}>Set up your credit or debit card</h2>
                    <form onSubmit={handleSubmit}>
                        <div className="card-main-imge">
                            <input
                                type="text"
                                id="cardNumber"
                                name="cardNumber"
                                value={formData.cardNumber}
                                onChange={handleCardNumberChange}
                                placeholder="1234 5678 9012 3456"
                                maxLength="19"
                                required
                            />
                            <div className="imges-all">
                                {formData.cardType && <img src={formData.cardImage} alt={formData.cardType} style={{ width: '73%' }} />}
                            </div>
                        </div>
                        <input
                            type="text"
                            id="cardHolder"
                            name="cardHolder"
                            value={formData.cardHolder}
                            onChange={handleChange}
                            placeholder="Name on Card"
                            required
                        />

                        <div className="expiry-cvv">
                            <div className="expiry">
                                <input
                                    type="text"
                                    id="expiryDate"
                                    name="expiryDate"
                                    value={formData.expiryDate}
                                    onChange={handleChange}
                                    placeholder="MM/YY"
                                    maxLength="5"
                                    required
                                />
                            </div>
                            <div className="cvv">
                                <input
                                    type="text"
                                    id="cvv"
                                    name="cvv"
                                    value={formData.cvv}
                                    onChange={handleChange}
                                    placeholder="CVV"
                                    maxLength="3"
                                    required
                                />
                            </div>
                        </div>

                        <div className="plain-select-div">
                            <div className="com-plain-changing" style={{ width: '70%' }}>
                                <h4 className="plainpricedata">{data?.name} Plan</h4>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <span className={`priceText ${promoCodeDiscount > 0 ? 'cutPrice' : ''}`}>${cutPrice.toFixed(2)}</span>
                                    {promoCodeDiscount > 0 && (
                                        <p className="paragrajcard" style={{ display: 'flex', alignItems: 'center', color: 'white' }}>
                                            <span style={{ padding: '0px 10px', color: "#EE2D2F" }}> Applied promo code  </span> ${discountedPrice}
                                        </p>
                                    )}
                                </div>
                            </div>
                            <div className="com-plain-changing" style={{ textAlign: 'right', width: "30%" }}>
                                <NavLink to={'/get-plans'} style={{ background: 'black', textDecoration: 'none' }}><a href="" className='navlinkText'>Change</a></NavLink>
                            </div>
                        </div>
                        <p className="pravicytext">
                            Your payments will be processed internationally. Additional bank fees may apply.<br /><br />
                            By checking the checkbox below, you agree to our Terms of Use, Privacy Statement, and that you are over 18. SecretLit will automatically continue your membership and charge the monthly membership fee (currently ${discountedPrice}) to your payment method until you cancel. You may cancel at any time to avoid future charges.
                        </p>

                                              <FormControlLabel control={<Checkbox checked={formData.agreement} onChange={(e) => setFormData({ ...formData, agreement: e.target.checked })} />} label="I agree" style={{ color: 'white', fontFamily: '"Noto Sans"', fontSize: '17px', fontWeight: '500', letterSpacing: '0px', textAlign: 'left' }} />


                  <ButtonCustom OnclickHandle={handleSubmit} Type="submit" Text="Start Membership" />
                    </form>
                    <ToastContainer
position="top-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="dark"
/>
                </div>
            </div>
        </div>
    );
}

export default CreditCardForm;
