// import React, { useEffect, useState } from 'react';
// import './Account.css';
// import { IoMdInformationCircleOutline } from "react-icons/io";
// import BookCardTem from '../../components/BookCardtem/BookCardTem';
// import { useNavigate } from 'react-router-dom';
// import axios from 'axios';

// const IsFavBooks = () => {
//     const [token, setToken] = useState('');
//     const [readbook, setRecentBooks] = useState([]);
//     const [loading, setLoading] = useState(false);
//     const [loadingRecomended, setLoadingRecomended] = useState(false);
//     const navigation = useNavigate();
//     const [recommendedData, setRecommendedData] = useState([]);

//     useEffect(() => {
//         const storedData = localStorage.getItem('user');
//         if (storedData) {
//             const parsedData = JSON.parse(storedData);
//             const TOKEN = parsedData.loginToken;
//             setToken(TOKEN);
//         } else {
//             console.log('No data found in local storage.');
//         }
//     }, []);

//     useEffect(() => {
//         const fetchRecentRead = async () => {
//             setLoading(true);
//             if (token) {
//                 try {
//                     const response = await axios.get('https://new-app-testing-2d30280db142.herokuapp.com/api/get-single-favourite-book', {
//                         headers: {
//                             Authorization: token
//                         }
//                     });
//                     setRecentBooks(response.data.books.reverse());
//                 } catch (error) {
//                     console.error('Error fetching recently read books:', error.response);
//                 } finally {
//                     setLoading(false);
//                 }
//             }
//         };
//         fetchRecentRead();
//     }, [token]);

//     const fetchBooksByRecommendation = async () => {
//         setLoadingRecomended(true);
//         try {
//             const response = await axios.get('https://new-app-testing-2d30280db142.herokuapp.com/api/get-all-recommended-book');
//             const data = response.data.recommendedBooks;
//             const booksArray = data.map(item => item.book);
//             setRecommendedData(booksArray);
//         } catch (error) {
//             console.error('Error fetching books:', error);
//         } finally {
//             setLoadingRecomended(false);
//         }
//     };

//     useEffect(() => {
//         fetchBooksByRecommendation();
//     }, []);

//     const handleHeartClick = async (bookId) => {
    
//         try {
//             // Start the process of removing the book from favorites
//             await axios.delete(`https://new-app-testing-2d30280db142.herokuapp.com/api/delete-favourite-book/${bookId}`, {
//                 headers: {
//                     // Include any necessary headers here
//                     Authorization: token, // Ensure you pass the correct token if needed
//                 },
//             });
            
//             // Update the local state to remove the book
//             setRecentBooks(prevBooks => prevBooks.filter(book => book.id !== bookId));
//         window.location.reload()
//         } catch (error) {
//             // Log error details for debugging
//             console.error('Error removing book from favorites:', error.response || error.message);
    
//             // Optional: Notify the user about the error
//             // e.g., display a toast notification or alert
//         }
//     };
//     const handleBookClick = (bookData) => {
//         navigation('/BookDetails', { state: { data: bookData } });
//     };

//     return (
//         <div className='recentRead-main' style={{ display: 'block', height: 'auto' }}>
//             <div className="rowdiv-recent additonRowDIn" style={{ background: 'black' }}>
//                 <div className="iconDiv">
//                     <h1 className="recenredRed-heading" style={{ background: 'black', fontFamily: '"Noto Sans SC", sans-serif' }}>
//                         Favorite Comics
//                     </h1>
//                 </div>
//                 {readbook?.length === 0 ? (
//                     <p className='paragrah-no-recenrtbook'>
//                         <IoMdInformationCircleOutline style={{ fontSize: '20px', marginRight: '4px' }} />
//                         There are no Favorite comics.
//                     </p>
//                 ) : (
//                     <div className="slider-container-rednring">
//                         {(loading ? Array.from(new Array(18)) : readbook)?.map((card, index) => (
//                             <div key={index} onClick={() => handleBookClick(card)} style={{ cursor: 'pointer' }} className='div-main-recentrendeing'>
//                                 <BookCardTem
//                                 loading={loading}
//                                  additionalFunction={() => handleHeartClick(card._id)}
//                                     // onHeartClick={(card)=>console.log(card._id)}
//                                     isFavorite={true}
//                                     bookId={card._id} // Ensure to pass bookId if required by your BookCardTem
//                                     Bookname={card?.bookTitle}
//                                     firstTopTag={card?.FirstTopTag}
//                                     secTopTag={card?.SecTopTag}
//                                     bookcategoriesFirst={card?.primaryCategory?.name}
//                                     bookcategoriesSec={card?.secondaryCategory?.name}
//                                     rating={card?.rating}
//                                     bookImg={card?.bookCoverImage?.url || card?.bookCoverImage}
//                                 />
//                             </div>
//                         ))}
//                     </div>
//                 )}
//             </div>
//         </div>
//     );
// };

// export default IsFavBooks;
import React, { useEffect, useState } from 'react';
import './Account.css';
import { IoMdInformationCircleOutline } from "react-icons/io";
import BookCardTem from '../../components/BookCardtem/BookCardTem';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Spinner } from 'react-bootstrap'; // Import Spinner component from react-bootstrap

const IsFavBooks = () => {
    const [token, setToken] = useState('');
    const [readbook, setRecentBooks] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingRecomended, setLoadingRecomended] = useState(false);
    const navigation = useNavigate();
    const [recommendedData, setRecommendedData] = useState([]);
console.log(readbook)
    useEffect(() => {
        const storedData = localStorage.getItem('user');
        if (storedData) {
            const parsedData = JSON.parse(storedData);
            const TOKEN = parsedData.loginToken;
            setToken(TOKEN);
        } else {
            console.log('No data found in local storage.');
        }
    }, []);

    useEffect(() => {
        const fetchRecentRead = async () => {
            setLoading(true);
            if (token) {
                try {
                    const response = await axios.get('https://new-app-testing-2d30280db142.herokuapp.com/api/get-single-favourite-book', {
                        headers: {
                            Authorization: token
                        }
                    });
                    setRecentBooks(response.data.books.reverse());
                } catch (error) {
                    console.error('Error fetching recently read books:', error.response);
                } finally {
                    setLoading(false);
                }
            }
        };
        fetchRecentRead();
    }, [token]);

    // const fetchBooksByRecommendation = async () => {
    //     setLoadingRecomended(true);
    //     try {
    //         const response = await axios.get('https://new-app-testing-2d30280db142.herokuapp.com/api/get-all-recommended-book');
    //         const data = response.data.recommendedBooks;
    //         const booksArray = data.map(item => item.book);
    //         setRecommendedData(booksArray);
    //     } catch (error) {
    //         console.error('Error fetching books:', error);
    //     } finally {
    //         setLoadingRecomended(false);
    //     }
    // };

    // useEffect(() => {
    //     fetchBooksByRecommendation();
    // }, []);

    const handleHeartClick = async (bookId) => {
        console.log(bookId)
        try {
            await axios.delete(`https://new-app-testing-2d30280db142.herokuapp.com/api/delete-favourite-book/${bookId}`, {
                headers: {
                    Authorization: token,
                },
            });
            
            setRecentBooks(prevBooks => prevBooks.filter(book => book._id !== bookId));
        } catch (error) {
            console.error('Error removing book from favorites:', error.response || error.message);
        }
    };

    const handleBookClick = (bookData) => {
        navigation('/BookDetails', { state: { data: bookData } });
    };

    return (
        <div className='recentRead-main' style={{ display: 'block', height: 'auto' }}>
            <div className="rowdiv-recent additonRowDIn" style={{ background: 'black' }}>
                <div className="iconDiv">
                    <h1 className="recenredRed-heading" style={{ background: 'black', fontFamily: '"Noto Sans SC", sans-serif', paddingLeft: 0 }}>
                        Favourites
                    </h1>
                </div>
                {loading ? (
                    <div className="spinner-container">
                        <Spinner animation="border" variant="danger" /> {/* Adjust spinner style as needed */}
                    </div>
                ) : readbook.length === 0 ? (
                    <p className='paragrah-no-recenrtbook'>
                        <IoMdInformationCircleOutline style={{ fontSize: '20px', marginRight: '4px' }} />
                        There are no Favourites.
                    </p>
                ) : (
                    <div className="slider-container-rednring">
                        {(loading ? Array.from(new Array(5)) : readbook)?.map((card, index) => (
                            <div key={index} onClick={() => handleBookClick(card)} style={{ cursor: 'pointer' }} className='div-main-recentrendeing'>
                                <BookCardTem
                                    loading={loading}
                                    // additionalFunction={() => handleHeartClick(card._id)}
                                    isFavorite={true}
                                    bookId={card._id}
                                    Bookname={card?.bookTitle}
                                    firstTopTag={card?.FirstTopTag}
                                    secTopTag={card?.SecTopTag}
                                    bookcategoriesFirst={card?.primaryCategory?.name}
                                    bookcategoriesSec={card?.secondaryCategory?.name}
                                    rating={card?.rating}
                                    bookImg={card?.bookCoverImage?.url || card?.bookCoverImage}
                                    onHeartClick={() => handleHeartClick(card._id)}
                                />
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default IsFavBooks;
