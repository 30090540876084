
import React, { useState, useEffect } from 'react';
import './Header.css';
import Logo from '../assets/images/logowhite.png';
import { NavLink, useNavigate } from 'react-router-dom';
import { CiSearch } from "react-icons/ci";
import { IoMdClose } from "react-icons/io";
import FormHeader from '../components/form/Form';
import GoogleIcon from '../assets/images/google (1).png'
import HistoryIcon from '../assets/images/history.png'
import PurshaseHis from '../assets/images/invoice.png'
import { MdOutlineStarPurple500 } from "react-icons/md";
import LogoutIcon from '../assets/images/logout.png'
import Coupon from '../assets/images/coupon.png'
import SettingIon from '../assets/images/settings.png'
import axios from 'axios';
import GenderIcon from '../assets/images/person.png'
function Header({ isMenuOpen, toggleMenu, closedMenu }) {
    const navigate = useNavigate();
    const [modalShow, setModalShow] = useState(false);
    const [user, setUser] = useState(null);
    const [newBooks, setNewBooks] = useState(false);

    useEffect(() => {
        // Check for user in local storage on component mount
        const storedUser = JSON.parse(localStorage.getItem('user'));
        if (storedUser) {
            setUser(storedUser);
        }
        fetchNewBooks()
    }, []);

    const handleLogin = (userData) => {
        // Save user data to state and local storage
        setUser(userData);
        localStorage.setItem('user', JSON.stringify(userData));
        setModalShow(false);
    };
    const fetchNewBooks = async () => {
        try {
          const response = await axios.get('https://new-app-testing-2d30280db142.herokuapp.com/books/get-all-newly-edit');
          const books = response.data?.fetchBooks;
          const lastVisit = localStorage.getItem('lastVisitNewPage');
          const lastVisitTime = lastVisit ? new Date(lastVisit).getTime() : 0;
          const newBooksAvailable = books.some(book => new Date(book.createdAt).getTime() > lastVisitTime);
    
          setNewBooks(newBooksAvailable);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
    const handleLogout = () => {
        // Clear user data from state and local storage
        setUser(null);
        localStorage.removeItem('user');
        localStorage.removeItem('paymentPlain');
        
        closedMenu()
    };

    return (
        <header className="header">
            <div className='innerdiv-display'>
            <div className={`hamburger ${isMenuOpen ? 'open' : ''}`} onClick={(e) => {
                e.stopPropagation();
                toggleMenu();
            }}>
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
            </div>
            <div className="logo"><img src={Logo} alt="Logo" style={{ height: '4vh' }} className='header-img' onClick={() => navigate('/')} /></div>
            <div className="icon-header-search"><CiSearch style={{ fontSize: '20px', color: 'white' }} /></div>
            </div>
            <div className="down-responsiveHeader">
                    <NavLink to="/" exact activeClassName="active" className='naviagtion-a' style={{marginLeft:0}}>Home</NavLink>
                    <NavLink to="/Genremobile"  exact activeClassName="active" className='naviagtion-a'>Genres</NavLink>
                    <NavLink to="/Series" exact activeClassName="active" className='naviagtion-a'>Series</NavLink>
              <NavLink to='/AudioBooks' exact activeClassName="active" className='naviagtion-a'>AudioBooks</NavLink>
              <NavLink to='/New' exact activeClassName="active" className='naviagtion-a' style={{position:'relative'}}>New {newBooks && (
                  <div style={{position: 'absolute',right:'-2px',top: '-5px'}}>
                    <MdOutlineStarPurple500 style={{ color: '#EE2D2F',  fontSize: '11px', }} />
                  </div>
                )}</NavLink>
                    </div>
            <nav className={`menu ${isMenuOpen ? 'open' : ''}`} onClick={(e) => e.stopPropagation()}>
                <div style={{ background: 'rgba(238, 45, 47, 1)', padding: '10px 17px', paddingBottom: '20px' }}>
                    <div className='iconWithLogoDiv'>
                        <div className="logo" onClick={closedMenu}><img src={Logo} alt="Logo" className='header-img' onClick={() => navigate('/')} /></div>
                        <IoMdClose style={{ fontSize: '28px', color: 'white' }} onClick={closedMenu} />
                    </div>
                    
                    <div className="button-header-responsive">
                        {user ? (
                            <>
                            <div className='btn-header-responsive-div-google'>
                            <span style={{paddingRight:'10px'}}><img src={GoogleIcon} alt="" style={{ borderRadius:' 50px',
    width: '30px',
    height: '30px',
    objectFit: 'contain',
    background: 'white',
    padding: '6px'
}} /></span>
                                <span className='user-email'>{user.email}</span>
                                </div>
                              
                            </>
                        ) : (
                            <>
                                <button className='btn-responsive-btn' onClick={() => setModalShow(true)}>Sign in</button>
                                <button className='btn-responsive-btn' onClick={() => setModalShow(true)}>Sign up</button>
                            </>
                        )}
                    </div>
                </div>
                <ul>
                    {user ? (
                        <>
                        <NavLink to='' onClick={closedMenu} style={{textDecoration:'none',background:'none',}} className='paragraph-text-menu'> <p className='paragraph-text-menu' style={{paddingBottom:'10px',paddingTop:'30px'}}> <img src={GenderIcon} alt="" style={{width:'20px',height:'20px',marginRight:5}} srcset="" /> Gender </p> </NavLink>
                             <NavLink to='mypage/RecentRead' onClick={closedMenu} style={{textDecoration:'none',background:'none',}} className='paragraph-text-menu'> <p className='paragraph-text-menu' style={{paddingTop:'15px'}}> <img src={HistoryIcon} alt="" style={{width:'20px',height:'20px',marginRight:5}} srcset="" /> Recently Read </p> </NavLink>
                             <NavLink to='mypage/is-favorite-books' onClick={closedMenu} style={{textDecoration:'none',background:'none' }} className='paragraph-text-menu'> <p className='paragraph-text-menu' style={{paddingTop:'15px'}} > <img src={PurshaseHis} alt="" style={{width:'19px',height:'20px',marginRight:5}} srcset="" /> Favorite </p></NavLink>
                             <NavLink to='mypage/is-favorite-books'onClick={closedMenu} style={{textDecoration:'none',background:'none' }} className='paragraph-text-menu'>   <p className='paragraph-text-menu' style={{paddingTop:'15px'}}  > <img src={Coupon} alt="" style={{width:'19px',height:'20px',marginRight:5}} srcset="" /> Promo Code</p> </NavLink> 
                             <NavLink to='mypage/accountSetting' onClick={closedMenu} style={{textDecoration:'none',background:'none' }} className='paragraph-text-menu'> <p className='paragraph-text-menu'  style={{paddingTop:'15px'}} > <img src={SettingIon} alt="" style={{width:'19px',height:'20px',marginRight:5}} srcset="" /> Account Settings </p></NavLink>
                             <NavLink to='mypage/customer-services' onClick={closedMenu} style={{textDecoration:'none',background:'none' }} className='paragraph-text-menu'> <p className='paragraph-text-menu'  style={{paddingTop:'15px'}} > <img src={SettingIon} alt="" style={{width:'19px',height:'20px',marginRight:5}} srcset="" /> Customer Services </p></NavLink>
                             <NavLink to='' onClick={handleLogout} style={{textDecoration:'none',background:'none' }} className='paragraph-text-menu'> <p className='paragraph-text-menu'  style={{paddingTop:'15px'}} > <img src={LogoutIcon} alt="" style={{width:'19px',height:'20px',marginRight:5}} srcset="" /> Logout </p></NavLink>
                        
                        </>
                    ) : (
                        <li><a href="#support">Customer Support</a></li>
                    )}
                </ul>
            </nav>
            <FormHeader show={modalShow} onHide={() => setModalShow(false)} setUser={handleLogin} />
        </header>
    );
}

export default Header;
