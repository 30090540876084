
import React, { useEffect, useState } from 'react'
import BookCardTem from '../components/BookCardtem/BookCardTem'
import './all.css'
import { Pagination } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'

const Series = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 18; // Set to 18 as per your requirement
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    window.scrollTo(0, 0);
  };

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get('https://backend.mysecretlit.com/api/user/getSeries');
      const formattedData = response.data.data.map((item) => ({
        ...item,
        id: item._id
      }));
      setData(formattedData?.reverse());
     
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchData();
  }, []);

  const navigate = useNavigate();

  return (
    <div style={{ width: '100%', background: 'black' }}>
      <div className='main-series-div-width'>
        <h2 className='h2-main-heading'>Series</h2>
        <div className='seriesdiv-row'>
          {(isLoading ? Array.from(new Array(18)) : currentItems)?.map((card, index) => (
            <div 
              key={index} 
              style={{cursor:'pointer'}} 
              className='custom-deatils-card' 
              onClick={() => navigate('/BookDetails', { state: { data: card } })}
            >
              <BookCardTem
                Bookname={card?.title}
                firstTopTag={card?.FirstTopTag}
                secTopTag={card?.SecTopTag}
                bookcategoriesFirst={card?.category?.name}
                bookcategoriesSec={card?.secondaryCategory?.name}
                rating={card?.rating}
                bookImg={card?.coverImage}
                loading={isLoading}
              />
            </div>
          ))}
        </div>
        {/* Show Pagination only if there are more than 18 items */}
        {data.length > itemsPerPage && (
          <Pagination
            count={Math.ceil(data.length / itemsPerPage)}
            page={currentPage}
            onChange={handlePageChange}
            variant="outlined"
          />
        )}
      </div>
    </div>
  );
};

export default Series;
